import { Phase } from '@/types/Phase';
import { Process } from '@/types/Process';

interface ProjectInterface {
    pro_id?: string,
    pro_pro_id?: number, //reference Project ID
    pro_pro_name?: string, //reference Project Name
    pro_org_id?: number,
    pro_org_name?: string,
    pro_name: string,
    pro_desc?: string,
    pro_prc_count?: number,
    pro_act_count?: number,
    pro_mst_count?: number,
    pro_pha_count?: number,
    role_count?: number,
    start_date?: string,
    ifc_foreign_ent?: number,
    pro_elm_cls_count?: number,
    pro_elm_typ_count?: number,
    pro_pty_cls_count?: number,
    pro_pty_typ_count?: number,
    ifc_foreign_prop?: number,
    is_template?: boolean,
    type?: string,
    status?: string,
    team_size?: number,
    pro_prc_nested: Process[],
    pro_pha_nested: Phase[],
    pro_change_date?: string, 
    pro_change_user?: string, 
    pro_create_date?: string, 
    pro_create_user?: string,
    pro_isc_id?: number,
    pro_isc_name?: string,
    pro_isc_version?: string,
    pro_rop_count?: string,
    pro_pers?: boolean,
    pro_type_code?: string,
    pro_access_all: boolean,
}

export class Project implements ProjectInterface {
    pro_name = '';
    pro_prc_nested = new Array<Process>();

    pro_id = '-1';
    //pro_pro_id = 103; //using the default Reference Project ID
    pro_pro_id = -1; //using the default Reference Project ID
    pro_pro_name = '';
    pro_org_id = -1;
    pro_org_name = '';
    pro_desc = '';
    pro_prc_count = -1;
    pro_act_count = -1;
    pro_mst_count = -1;
    pro_pha_count = -1;
    role_count = -1;
    start_date = '';
    ifc_foreign_ent = -1;
    pro_elm_cls_count = -1;
    pro_elm_typ_count = -1;
    pro_pty_cls_count = -1;
    pro_pty_typ_count = -1;
    ifc_foreign_prop = -1;
    is_template = false;
    type = '';
    status = '';
    team_size = -1;
    pro_pha_nested= new Array<Phase>();
    pro_change_date = ''; 
    pro_change_user = ''; 
    pro_create_date = ''; 
    pro_create_user = '';
    pro_isc_id = -1;
    pro_isc_name = '';
    pro_isc_version = '';
    pro_rop_count = '';
    pro_pers = false;
    pro_type_code = 'pro-usr';
    pro_access_all = false;

    constructor() {
        return this;
    }
}

export function instanceOfProject(data: any): data is Project {
    return 'pro_id' in data;
}