
import { Component, Vue } from 'vue-property-decorator';
import BpsUserWidget from '@/components/general/BpsUserWidget.vue';


@Component({
  components: {
      BpsUserWidget
  },
  props: {
  }
})
/**
 * Main navigation component for the whole web application
 */
export default class BpsMainNavigation extends Vue {

    /**
     * Checks and confirms whether the current user is logged in or not.
     */
    get isLoggedIn() : boolean {
        //console.log("isLoggedIn?", this.$store.getters.userId);
        return this.$store.getters.isLoggedIn && this.$store.getters.userId != undefined;
    }

    /**
     * Checks and confirms whether the current user has a valid license.
     */
    get isLicensed() : boolean {
        return this.$store.getters.isLicensed;
    }
}
