import { HttpClient } from '@/utils/HttpClient';
import { Milestone } from '@/types/Milestone';
import Vue from 'vue';

export class MilestonesApi extends HttpClient {
    public constructor() {
        super(process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_PROFILE_SUFFIX);
    }

    public fetchMilestone = (milestoneId : number) => this.instance
    .get<Milestone>(`milestones/${milestoneId}` )
    .then(response => response.data);

    public fetchMilestoneWithProcesses = (milestoneId : number) => this.instance
    .get<Milestone>(`milestones/${milestoneId}&nested=processes` )
    .then(response => response.data);

    public fetchMilestones = (projectId : number, stats : boolean = true) => {
        let url = `milestones?mst_pro_id=${projectId}&nested=processes`;
        if(stats == true) {
            url += `&statistics=true`;
        }
        return this.instance
        .get<Milestone[]>(url)
        .then(response => response.data);
    }

    public fetchAllMilestones = () => this.instance
    .get<Milestone[]>(`milestones?nested=processes` )
    .then(response => response.data);

    public createMilestone = (body: Milestone, msgCompleted: string) => {
        const {mst_id, mst_change_date, mst_change_user, mst_create_date, mst_create_user, 
            mst_pha_name, mst_prc_nested, mst_pro_id, mst_pro_name, mst_org_id, 
            mst_prc_count, mst_obj_count, ...toBeCreated } = body;
        return this.instance.post('milestones', toBeCreated)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public editMilestone = (body: Milestone, msgCompleted: string) => {
        //spread the body to another variable without pro_id and
        //invalid fields (ES9 Object Rest Operator)
        const {mst_change_date, mst_change_user, mst_create_date, mst_create_user,
            mst_pha_name, mst_prc_nested, mst_pro_id, mst_pro_name, mst_org_id, 
            mst_prc_count, mst_obj_count, ...edited } = body;        
        return this.instance.patch(`milestones/${body.mst_id}`, edited)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    // public updateMilestoneSeq = (id : number, seq : number, msgCompleted: string) => {
    //     const newSeq = {mst_seq: seq};
    //     return this.instance.patch(`milestones/${id}`, newSeq)
    //     .then(response => {
    //         Vue.$toast.success(msgCompleted);
    //         return response.data;
    //     });
    // }

    public updateMilestonePhase = (id : number, phase_id : number) => {
        const newPhase = {mst_pha_id: phase_id};
        return this.instance.patch(`milestones/${id}`, newPhase)
        .then(response => response.data);
    }

    public deleteMilestone = (milestoneId: number, msgCompleted: string) => {
        return this.instance.delete(`milestones/${milestoneId}`)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public resequenceMilestones = (requests: Array<{mst_id: number, mst_seq: number}>) => {
        const httpRequests = new Array();
        requests.forEach((milestone:{mst_id: number, mst_seq: number}) => {
            httpRequests.push(this.instance.patch(`milestones/${milestone.mst_id}`, {mst_seq: milestone.mst_seq}));
        });
        return Promise.all(requests);
    }
}