import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { Milestone } from '@/types/Milestone';
import { Phase } from '@/types/Phase';
import { PhasesApi } from '@/services/phases.api';
import { Process } from '@/types/Process';
import { Project } from '@/types/Project';

@Module//({ namespaced: true, name: 'new.project' })
export default class CanvasModule extends VuexModule {
    _canvasProject : Project = new Project();
    newPhases : Phase[] = new Array<Phase>(); //Phases used in the canvas to create a new Project
    newMilestones : Milestone[] = new Array<Milestone>(); //Milestones used in the canvas to create a new Project
    newProcesses : Process[] = new Array<Process>(); //Processes used in the canvas to create a new Project

    get canvasProjectName() {
        return this.canvasProject.pro_name;
    }

    get canvasProjectDescription() {
        return this.canvasProject.pro_desc;
    }

    get canvasProjectOrganisationId() {
        return this.canvasProject.pro_org_id;
    }

    get canvasProject() {
        return this._canvasProject;
    }

    get canvasPhases() {
        return this._canvasProject.pro_pha_nested;
        //return this.newPhases;
    }

    get canvasMilestones() {
        return this.newMilestones;
    }

    get canvasProcesses() {
        return this.newProcesses;
    }

    @Action
    setCanvasPhases(phases : Phase[]) {
        this.context.commit('SET_CANVAS_PHASES', phases);
    }

    @Action
    setCanvasMilestones(milestones : Milestone[]) {
        this.context.commit('SET_CANVAS_MILESTONES', milestones);
        this.context.dispatch('resequenceCanvas');
    }

    @Action
    removeCanvasMilestone(milestone : Milestone) {
        this.context.commit('REMOVE_CANVAS_MILESTONE', milestone);
        this.context.dispatch('resequenceCanvas');
    }

    @Action
    removeCanvasProcess(process : Process) {
        this.context.commit('REMOVE_CANVAS_PROCESS', process);
        this.context.dispatch('resequenceCanvas');
    }

    @Action
    addCanvasProcessToMilestone(payload : {process:Process, milestone:Milestone, newIndex:number}) {
        this.context.commit('ADD_CANVAS_PROCESS_TO_MILESTONE', payload);
        this.context.dispatch('resequenceCanvas');
    }

    @Action
    removeCanvasProcessFromMilestone(payload : {process:Process, milestone:Milestone, oldIndex:number}) {
        this.context.commit('REMOVE_CANVAS_PROCESS_FROM_MILESTONE', payload);
        this.context.dispatch('resequenceCanvas');
    }

    @Action
    changeCanvasProcessOrder(payload : {milestone:Milestone, process:Process, newIndex:number, oldIndex:number}) {
        this.context.commit('CHANGE_CANVAS_PROCESS_ORDER', payload);
        this.context.dispatch('resequenceCanvas');
    }

    @Action
    resequenceCanvas() {
        //we make a deep copy of the milestones array to change it as a whole
        const previousMilestones = this.newMilestones.map(obj => ({...obj}));
        //we make a deep copy of the processes array to change it as a whole
        const previousProcesses = this.newProcesses.map(obj => ({...obj}));

        let milestoneId = -1;
        let milestoneCounter = 1;
        let processCounter = 1;
        previousMilestones.forEach((milestone:Milestone) => {
            if(milestone.mst_id != milestoneId) {
                milestoneId = milestone.mst_id;
                milestone.mst_seq = milestoneCounter++;
                processCounter = 1;
            }
            previousProcesses.forEach((process:Process) => {
                if(process.prc_mst_id == milestoneId) {
                    process.prc_seq = processCounter++;
                }
            })
        })

        this.context.commit('SET_CANVAS_MILESTONES', previousMilestones);
        this.context.commit('SET_CANVAS_PROCESSES', previousProcesses);
    }

    @Action
    resetCanvasProject() {
        this.context.commit('RESET_CANVAS_PROJECT');
    }

    @Action
    changePhaseOfCanvasMilestone(payload : {milestone: Milestone, phaseId: number, newIndex: number}) {
        //we create an independent copy of the Milestones to be modified
        const newState = this.newMilestones.map(obj => ({...obj}));
        //we find the correct milestone in the list of all milestones
        const milestone = payload.milestone;
        //we search for the phase as well, in order to change its name within the Milestone
        const phase = this.newPhases.find((phase:Phase) => phase.pha_id == payload.phaseId);
        //if we find it
        if(milestone != undefined && phase != undefined) {
            const oldIndex = newState.indexOf(milestone);
            console.log("changePhaseOfMilestone",oldIndex, milestone);
            milestone.mst_pha_id = payload.phaseId;
            milestone.mst_pha_name = phase.pha_name;
            if(oldIndex != undefined) {
                //the newIndex is initially calculated while the element is still at its previous position
                //this is why, if we move the element down in the array (ie. to a new index bigger thans the previous index), 
                //we need to account for the fact that the element is still taking a place in the array.
                if(oldIndex<payload.newIndex) {
                    payload.newIndex--;
                }
                //from https://stackoverflow.com/a/5306832
                newState.splice(payload.newIndex, 0, newState.splice(oldIndex, 1)[0]);
            }

            //then we update the store (we could have put this in the callback of the API call below but it would have caused some 
            //rendering issue where the Milestone still belongs to the previous Phase for a split second).
            this.context.commit('SET_CANVAS_MILESTONES', newState);
            this.context.dispatch('resequenceCanvas');
        }
    }

    @Action
    changeCanvasMilestoneOrder(payload : {phaseId: number, oldIndex: number, newIndex: number}) {
        this.context.commit('CHANGE_CANVAS_MILESTONE_ORDER', payload);
        this.context.dispatch('resequenceCanvas');
    }

    @Action
    setupCanvasProject(refProjectId : number) {
        if(refProjectId != undefined) {
            const api = new PhasesApi();
            api.fetchProjectPhases(refProjectId)
            .then(res => {
                const canvasProject = new Project();
                canvasProject.pro_pro_id = refProjectId;
                res.forEach((phase:Phase) => {
                    phase.pha_mst_nested = new Array<Milestone>();
                })
                canvasProject.pro_pha_nested = res;
                this.context.commit('SET_CANVAS_PROJECT', canvasProject);
            });
        }
    }

    @Action
    updateCanvasProject(project : Project) {
        this.context.commit("SET_CANVAS_PROJECT", project);
    }

    @Action
    setCanvasProjectName(name : string) {
        this.context.commit("SET_CANVAS_PROJECT_NAME", name);
    }

    @Action
    setCanvasProjectDescription(desc : string) {
        this.context.commit("SET_CANVAS_PROJECT_DESCRIPTION", desc);
    }

    @Action
    setCanvasProjectOrganisationId(organisationId : number) {
        this.context.commit("SET_CANVAS_PROJECT_ORGANISATION_ID", organisationId);
    }

    /**
     * 
     * MUTATIONS
     * 
     */

    @Mutation
    SET_CANVAS_PROJECT(project : Project) {
        //save the orgId to a temp variable 
        const orgId = this._canvasProject.pro_org_id;
        this._canvasProject = project;
        //if the previously defined orgId is relevant, we put define it back on the canvas Project
        if(orgId != -1) {
            this._canvasProject.pro_org_id = orgId;
        }
    }

    @Mutation
    SET_CANVAS_PROJECT_NAME(name : string) {
        this._canvasProject.pro_name = name;
    }

    @Mutation
    SET_CANVAS_PROJECT_DESCRIPTION(desc : string) {
        this._canvasProject.pro_desc = desc;
    }

    @Mutation
    SET_CANVAS_PROJECT_ORGANISATION_ID(orgId : number) {
        this._canvasProject.pro_org_id = orgId;
    }

    @Mutation
    SET_CANVAS_PHASES(phases : Phase[]) {
        this.newPhases = phases;
        this.newPhases.forEach((phase:Phase) => phase.pha_mst_nested = new Array<Milestone>());
    }

    @Mutation
    SET_CANVAS_MILESTONES(milestones : Milestone[]) {
        this.newMilestones = milestones;
        
        //repopulating Canvas Processes
        this.newProcesses = new Array<Process>();
        milestones.forEach((milestone:Milestone)=> {
            if(milestone.mst_prc_nested) {
                this.newProcesses = this.newProcesses.concat(milestone.mst_prc_nested);
            }
        })
    }

    @Mutation
    SET_CANVAS_PROCESSES(processes : Process[]) {
        this.newProcesses = processes;
    }

    @Mutation
    REMOVE_CANVAS_MILESTONE(milestone : Milestone) {
        const stone = this.newMilestones.find((mst:Milestone) => mst.mst_id == milestone.mst_id);
        if(stone != undefined) {
            const idx = this.newMilestones.indexOf(stone);
            this.newMilestones.splice(idx,1);
        }

        //repopulating Canvas Processes
        this.newProcesses = new Array<Process>();
        this.newMilestones.forEach((milestone:Milestone)=> {
            if(milestone.mst_prc_nested) {
                this.newProcesses = this.newProcesses.concat(milestone.mst_prc_nested);
            }
        })
    }

    @Mutation
    REMOVE_CANVAS_PROCESS(process : Process) {
        const prc = this.newProcesses.find((prc:Process) => prc.prc_id == process.prc_id);
        if(prc != undefined) {
            const idx = this.newProcesses.indexOf(prc);
            this.newProcesses.splice(idx,1);
        }

        const milestoneToModify = this.newMilestones.find((milestone:Milestone) => milestone.mst_id == process.prc_mst_id);
        if(milestoneToModify && milestoneToModify.mst_prc_nested) {
            const processToRemove = milestoneToModify.mst_prc_nested.find((prc:Process) => prc.prc_id == process.prc_id);
            if(processToRemove) {
                const idx = milestoneToModify.mst_prc_nested.indexOf(processToRemove);
                milestoneToModify.mst_prc_nested.splice(idx,1);
            }
        }
    }

    @Mutation
    ADD_CANVAS_PROCESS_TO_MILESTONE(payload : {process:Process, milestone:Milestone, newIndex:number}) {
        const milestone = this.newMilestones.find((mst:Milestone) => mst.mst_id == payload.milestone.mst_id);
        if(milestone && milestone.mst_prc_nested) {
            milestone.mst_prc_nested.splice(payload.newIndex, 0, payload.process);
        }
    }

    @Mutation
    REMOVE_CANVAS_PROCESS_FROM_MILESTONE(payload : {process:Process, milestone:Milestone, oldIndex:number}) {
        const milestone = this.newMilestones.find((mst:Milestone) => mst.mst_id == payload.milestone.mst_id);
        if(milestone && milestone.mst_prc_nested) {
            milestone.mst_prc_nested.splice(payload.oldIndex, 1);
        }
    }

    @Mutation
    CHANGE_CANVAS_PROCESS_ORDER(payload : {milestone:Milestone, process:Process, newIndex:number, oldIndex:number}) {
        const milestone = this.newMilestones.find((mst:Milestone) => mst.mst_id == payload.milestone.mst_id);
        if(milestone && milestone.mst_prc_nested) {
            //from https://stackoverflow.com/a/5306832
            milestone.mst_prc_nested.splice(payload.newIndex, 0, milestone.mst_prc_nested.splice(payload.oldIndex, 1)[0]);
            console.log(milestone.mst_prc_nested);
        }
    }

    @Mutation
    CHANGE_CANVAS_MILESTONE_ORDER(payload : {phaseId: number, oldIndex: number, newIndex: number}) {
        console.log("CHANGE_CANVAS_MILESTONE_ORDER", this.newMilestones);
        //from https://stackoverflow.com/a/5306832
        this.newMilestones.splice(payload.newIndex, 0, this.newMilestones.splice(payload.oldIndex, 1)[0]);
        console.log("CHANGE_CANVAS_MILESTONE_ORDER", this.newMilestones);
    }

    @Mutation
    RESET_CANVAS_PROJECT() {
        this.newMilestones = new Array<Milestone>();
        this.newProcesses = new Array<Process>();
    }
}
