export interface UserInterface {
    usr_email: string,
    usr_pw?: string,

    usr_id: number,
    usr_name: string,
    usr_name_first: string,
    usr_org_name?: string,
    usr_org_id?: number,
    usr_image?: string,

    usr_app_admin? : boolean,
    usr_org_admin? : Array<number>,
    usr_org_emp? : Array<number>,
    usr_org_pl? : Array<number>,
    usr_pro_admin? : Array<number>,
    usr_pro_read? : Array<number>,
    usr_pro_write? : Array<number>,
    
    usr_create_date?: string,
    usr_change_date?: string,
    usr_create_user?: string,
    usr_change_user?: string,
    usr_app_conn?: string,

    usr_login_date?: string, 
    usr_login_ip?: string

    usr_org_pers?: Array<number>,
    usr_org_pers_id?: number,
    usr_org_pers_name?: string,
    usr_has_license?: Boolean
}

export class User implements UserInterface {
    usr_email = '';
    usr_pw? = '';

    usr_id = -1;
    usr_name = '';
    usr_name_first = '';
    usr_org_name? = '';
    usr_org_id? = -1;
    usr_image? = '';

    usr_app_admin? = false;
    usr_org_admin? = new Array<number>();
    usr_org_emp? = new Array<number>();
    usr_org_pl? = new Array<number>();
    usr_pro_admin? = new Array<number>();
    usr_pro_read? = new Array<number>();
    usr_pro_write? = new Array<number>();
    
    usr_create_date? = '';
    usr_change_date? = '';
    usr_create_user? = '';
    usr_change_user? = '';
    usr_app_conn? = '';

    usr_login_date? = ''; 
    usr_login_ip? = '';

    usr_org_pers? = new Array<number>();
    usr_org_pers_id? = -1;
    usr_org_pers_name? = '';
    usr_has_license? = false;

    constructor() {
        return this;
    }
}

export function instanceOfUser(data: any): data is User {
    return 'usr_id' in data;
}