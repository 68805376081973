import { APIError } from '@/types/APIError';

export class ErrorHandler {
    field_dict_de : { [key: string]: string } = {
        'act_name':'Aktivität Name',
        'act_desc':'Aktivität Beschreibung',
        'elm_name':'Element Name',
        'elm_desc':'Element Beschreibung',
        'org_name':'Organisation Name',
        'org_desc':'Beschreibung Organisation',
        'prc_name':'Prozess Name',
        'prc_desc':'Prozess Beschreibung',
        'pro_name':'Projektname',
        'pro_desc':'Projektbeschreibung',
        'pty_name':'Eigenschaft Name',
        'pty_desc':'Eigenschaft Beschreibung',
        'rop_name':'Projektrolle Name',
        'rop_desc':'Projektrolle Beschreibung',
        'usr_name':'Anmeldename',
        'usr_name_first':'Nutzende Vorname',
        'usr_desc':'Nutzende Beschreibung',
        'usr_email':'Nutzende E-Mail',
        'pro_org_id':'Projekt Organisation-ID',
        'pro_org_name': 'Projekt Organisation Name',
        'pro_mst_count' : 'Projekt Meilenstein Anzahl',
        'pro_pha_count' : 'Projekt Phasen Anzahl',
        'pro_pha_nested' : 'Projekt eingeschlossene Phasen',
        'pro_pers' : 'Projekt Personal flag',
        'usr_login_date' : "Nutzende Login-Datum",
        'usr_login_ip' : 'Nutzende IP-address',
        'prc_pro_id' : 'Prozess Projekt-ID',
        'usr_org_pers' : 'Persönliche Organisation',
        'usr_org_pers_id' : 'Persönliche Organisation-ID',
        'usr_org_pers_name' : 'Persönliche Organisation Name',
        'usr_pw' : 'Nutzende Kennwort',

    };

    field_dict : { [key: string]: string } = {
        'act_name':'Activity name',
        'act_desc':'Activity description',
        'elm_name':'Element name',
        'elm_desc':'Element description',
        'org_name':'Organisation name',
        'org_desc':'Organisation description',
        'prc_name':'Process name',
        'prc_desc':'Process description',
        'pro_name':'Project name',
        'pro_desc':'Project description',
        'pty_name':'Property name',
        'pty_desc':'Property description',
        'rop_name':'Project Role name',
        'rop_desc':'Project Role description',
        'usr_name':'User last name',
        'usr_name_first':'User first name',
        'usr_desc':'User description',
        'usr_email':'User e-mail',
        'pro_org_id':'Project Organisation Identifier',
        'pro_org_name': 'Project Organisation name'
    };
      

    process(error : APIError) : string {
        //console.log("Errorhandler process", error);
        let message = "";

        if(error.message != null) {
            message = error.message;
            if(error.internal_code != '') {
                message += " (internal code: "+ error.internal_code+")";
            }
            //message += "<br />";
        }

        if(error.details !== null && typeof error.details === 'object' && Object.keys(error.details).length > 0) {
            const keys = Object.keys(error.details);
            for(let i=0; i<keys.length; i++) {
                const key_name = keys[i];
                if(error.details[key_name] != null) {
                    //let msg = "<strong>" +this.field_dict_de[key_name] + "</strong> is " + error.details[key_name].toLowerCase() + "<br />";
                    //console.log("this.field_dict_de[key_name]", this.field_dict_de[key_name]);
                    const fieldname = this.field_dict_de[key_name] != undefined ? this.field_dict_de[key_name] : key_name;
                    const msg = "\n" + fieldname + " ist " + error.details[key_name].toLowerCase();
                    message += msg;
                }
            }
        }
        else if(typeof error.details === 'string') {
            message += ' ' + error.details;
        }

        return message;
    }
}
