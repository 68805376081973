import AppRoutes from './routes';
import Vue from 'vue';
import VueRouter from 'vue-router';

//import { UserApi } from '@/services/user.api';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history", //removes the hash from URLs
    base: process.env.VUE_APP_PUBLIC_PATH,
    /* base: process.env.NODE_ENV === 'fhnw-staging' || process.env.NODE_ENV === 'fhnw-development'
    ? '/bim-profsrv/'
    : '/', */
    routes: AppRoutes,
    scrollBehavior (to, from) 
    {
        //checking if the name of the route is different before scrolling back to top of the page
        //this prevents scrolling to top if we are still on the same page only with different parameters
        if (to.name != from.name) {
            return { x: 0, y: 0 }
        }
    }      
});

export default router;
