
import "@fontsource/inter/300.css"; // Weight 300.
import "@fontsource/inter/500.css"; // Weight 500.
import "@fontsource/inter/variable-full.css";
import { Component, Vue } from 'vue-property-decorator';
import BpsMainNavigation from '@/components/general/BpsMainNavigation.vue';
import BpsDevAPISwitcher from '@/components/general/BpsDevAPISwitcher.vue';
import BpsMessageToaster from '@/components/general/BpsMessageToaster.vue';
import { StatusRetriever } from '@/utils/StatusRetriever';
import { PermissionsRefresher } from '@/utils/PermissionsRefresher';
import { AuthApi } from "@/services/auth.api";


@Component({
  components : {
    BpsMainNavigation,
    BpsDevAPISwitcher,
    BpsMessageToaster
  }
})
/**
 * Main app component
 */
export default class App extends Vue {
    modalActivated : boolean = false;
    Yposition : number = 0;
    loading : boolean = true;

    preChecks() {

        const api = new StatusRetriever();
        api.fetchStatus()
        .then(res => {
            if(res.api_version != process.env.VUE_APP_API_VERSION && this.$route.name != 'maintenance') {
                this.$store.dispatch('setMaintenanceMode', true);
                if(this.$route.name != 'maintenance') {
                    this.$router.push({
                        name: 'maintenance'
                    }).catch(() => {});
                }
            }
        })
        .catch(error => {
            if (503 === error.response.status || 404 === error.response.status) {
                this.$store.dispatch('setMaintenanceMode', true);
                if(this.$route.name != 'maintenance') {
                    this.$router.push({
                        name: 'maintenance'
                    }).catch(() => {});
                }
            }
        });
        this.loading = false;
    }

    mounted() {
        this.$router.onReady(() => {
            //this.preChecks();
        })

        //event callback for activating any modal 
        this.$root.$on('globalModal', (event : {modalActive: boolean}) => {
            if(event.modalActive == true) {
                // When the modal is shown, we want a fixed body
                this.Yposition = window.scrollY;
                document.body.style.position = 'fixed';
                //document.body.style.width = 'calc(100vw - 16px)';
                //on new browsers the scrollbar doesn't take horizontal space anymore
                document.body.style.width = 'calc(100vw)';
                document.body.style.top = `-${this.Yposition}px`;
            }
            else {
                // When the modal is hidden...
                //const scrollY = document.body.style.top;
                //console.log('else',scrollY);
                document.body.style.position = '';
                document.body.style.width = 'auto';
                document.body.style.top = '';
                //window.scrollTo(0, parseInt(scrollY || '0') * -1);
                window.scrollTo(0, this.Yposition);
            }
        });
    }

    checkAuth() {
        const localUser = localStorage.getItem('user');
        const code = localStorage.getItem('code');
        const session_state = localStorage.getItem('session_state');
        const redirect_uri = localStorage.getItem('redirect_uri');
        if (localUser == null || localUser == undefined ) {
            //this.$router.onReady(() => {
                //console.log("App starts preChecks - not logged in", this.$route.meta.notAuthRequired);
                console.log("App starts preChecks - not logged in", this.$route.meta);
                //if(!this.$route.meta.notAuthRequired || this.$route.meta.notAuthRequired == undefined) {

                //1st scenario, the user has a TrustID code in their LocalStorage
                //if(localStorage.getItem('code') != undefined) {
                if(code != null && session_state != null && redirect_uri != null) {
                    const api = new AuthApi();
                    api.transmitCode(code, session_state, redirect_uri)
                    .then(response => {
                        console.log("Transmitted TrustID code from LocalStorage", response)
                        this.$store.dispatch('login' , {token: response.token.access_token, user: response.usr } )
                        .then(() => {
                            //push to the last visited page if defined or back to the homepage
                            /* this.$router
                            .push(this.$route.query.redirect? this.$route.query.redirect.toString() : '/')
                            .catch(err => {
                                this.$log.error('[Navigation Error] :' + err);
                            }); */
                        })
                    })
                }
                //2nd scenario: the user is coming back from CRB TrustID service with a code and a session_state
                else if(this.$route.query.session_state != undefined && this.$route.query.code != undefined && redirect_uri != null) {
                    console.log("Received code from TrustID", this.$route.query.code);
                    localStorage.setItem('session_state', this.$route.query.session_state.toString());
                    localStorage.setItem('code', this.$route.query.code.toString());
                    const api = new AuthApi();
                    api.transmitCode(this.$route.query.code.toString(), this.$route.query.session_state.toString(), redirect_uri)
                    .then(response => {
                        console.log("Transmitted TrustID code from query-string", response)
                        this.$store.dispatch('login' , {
                            token: response.token.access_token, 
                            refresh_token: response.token.refresh_token,
                            user: response.usr } )
                        .then(() => {
                            //push to the last visited page if defined or back to the homepage
                            /* this.$router
                            .push(this.$route.query.redirect? this.$route.query.redirect.toString() : '/')
                            .catch(err => {
                                this.$log.error('[Navigation Error] :' + err);
                            }); */
                        })
                    })
                }
                else if(!this.$route.matched.some(record => record.meta.notAuthRequired)) {
                    console.log("Authentication required", this.$route);
                    this.loading = false;
                    /* this.$router.push({
                        name: 'login',
                        query: { redirect: this.$router.currentRoute.fullPath } 
                    }).catch(() => {}); */
                }
                else {
                    this.loading = false;
                }
            //})
        } else {
            //if the user is logged in, we call a special util class PermissionsRefresher that is independent 
            //of HttpClient (hence independent from the router itself to avoid circular reference)
            //to get the latest permissions and update the Vuex store with them
            const user = JSON.parse(localUser);
            const api = new PermissionsRefresher();
            api.fetchUserPermissions(user.usr_id)
            .then(res => {
                //console.log("permissions refresher response", res);
                this.$store.dispatch('setUserPermissions', res);
            })
            .catch(error => {
                if (401 === error.response.status) {
                    //console.log('catch error', error.response.data.internal_code);
                    if( (error.response.data.internal_code == 'AUTH' 
                        || error.response.data.internal_code == 'AUTH_NO_TOK' 
                        || error.response.data.internal_code == 'AUTH_EXP_TOK' 
                        || error.response.data.internal_code == 'AUTH_BE_TOK' 
                        || error.response.data.internal_code == 'AUTH_INV_TOK')
                        ) {
                            //console.log('_handleError', error.toJSON());
                            Vue.$toast.error(error.response.data.message);
                            const redirectPath = this.$router.currentRoute.fullPath;
                            this.$store.dispatch('logoutWithError', redirectPath);
                            //this.loading = false;
                            /* this.$router.push({
                                name: 'login',
                                query: { redirect: this.$store.getters.loginRedirect } 
                            })
                            .catch(err => {
                                this.$log.error('[Navigation Error] :' + err);
                                this.loading = false;
                            }); */
                    }
                }
            });
        }
    }

    get isLoggedIn() {
        return this.$store.getters.isLoggedIn;
    }

    get frontend_version() {
        return process.env.VUE_APP_FRONTEND_VERSION;
    }

    get api_version() {
        return process.env.VUE_APP_API_VERSION;
    }
    
    get maintenanceMode() : boolean {
        return this.$store.getters.maintenanceModeActive;
    }
}
