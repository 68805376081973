import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
//import EnvProvider from 'jvjr-docker-env';

@Module//({ namespaced: true, name: 'auth' })
export default class DebugModule extends VuexModule {
    apiBaseUrl : string = process.env.VUE_APP_API_BASE_URL;
    mockupEnabled : boolean = localStorage.getItem('mockupEnabled')? true : false;
    apiProfile : string = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_PROFILE_SUFFIX : process.env.VUE_APP_PROFILE_SUFFIX;
    apiUser : string = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_USER_SUFFIX : process.env.VUE_APP_USER_SUFFIX;
    apiAuth : string = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_AUTH_SUFFIX : process.env.VUE_APP_AUTH_SUFFIX;
    apiRef : string = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_REF_SUFFIX : process.env.VUE_APP_REF_SUFFIX;

    get baseUrl() : string {
        return this.apiBaseUrl;
    }

    get ProfileUrl() : string {
        if(this.apiProfile === undefined) {
            return this.apiBaseUrl;
        }
        return this.apiBaseUrl+this.apiProfile;
    }

    get UserUrl() : string {
        return this.apiBaseUrl+this.apiUser;
    }

    get AuthUrl() : string {
        return this.apiBaseUrl+this.apiAuth;
    }

    get ReferenceUrl() : string {
        return this.apiBaseUrl+this.apiRef;
    }

    get isMockupEnabled() : boolean {
        return this.mockupEnabled;
    }

    @Mutation
    toggle_api_mode(){
        if(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
            this.mockupEnabled = !this.mockupEnabled;
            
            this.apiBaseUrl = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_API_BASE_URL : process.env.VUE_APP_API_BASE_URL;
            localStorage.setItem('apiBaseUrl', this.apiBaseUrl);

            this.apiProfile = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_PROFILE_SUFFIX : process.env.VUE_APP_PROFILE_SUFFIX;
            this.apiUser = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_USER_SUFFIX : process.env.VUE_APP_USER_SUFFIX;
            this.apiAuth = this.mockupEnabled == true ? process.env.VUE_APP_MOCKUP_AUTH_SUFFIX : process.env.VUE_APP_AUTH_SUFFIX;
        }
    }

    @Mutation
    ACTIVATE_REAL_API(){
        if(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') {
            this.mockupEnabled = false;
            
            this.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
            localStorage.setItem('apiBaseUrl', this.apiBaseUrl);

            this.apiProfile = process.env.VUE_APP_PROFILE_SUFFIX;
            this.apiUser = process.env.VUE_APP_USER_SUFFIX;
            this.apiAuth = process.env.VUE_APP_AUTH_SUFFIX;
        }
    }

    @Action
    switchApiMode() {
      this.context.commit('toggle_api_mode');
    }

    @Action
    setRealApiMode() {
      this.context.commit('ACTIVATE_REAL_API');
    }

}