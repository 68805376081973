import Vue from 'vue';
import Vuex from 'vuex';
import GeneralModule from '@/store/modules/general.module';
import ProjectModule from '@/store/modules/project.module';
import AuthModule from '@/store/modules/auth.module';
import ConfigModule from '@/store/modules/config.module';
import NewProjectModule from '@/store/modules/new.project.module';
import CanvasModule from '@/store/modules/canvas.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    GeneralModule,
    ProjectModule,
    //ProcessModule,
    AuthModule,
    ConfigModule,
    //SandboxModule,
    NewProjectModule,
    CanvasModule,
  },
  strict: process.env.NODE_ENV !== 'production'
});
