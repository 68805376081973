import { render, staticRenderFns } from "./BpsUserPlanLabel.vue?vue&type=template&id=ea8560b8&scoped=true&"
import script from "./BpsUserPlanLabel.vue?vue&type=script&lang=ts&"
export * from "./BpsUserPlanLabel.vue?vue&type=script&lang=ts&"
import style0 from "./BpsUserPlanLabel.vue?vue&type=style&index=0&id=ea8560b8&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea8560b8",
  null
  
)

export default component.exports