import { HttpClient } from '@/utils/HttpClient';

export class AuthApi extends HttpClient {
    public constructor() {
        super(process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_AUTH_SUFFIX);
    }

    public login = (payload : {email: string, password: string}) => {
        return this.instance.post('login', payload)
        .then(response => response.data);
    }

    public logout = () => this.instance.get('logout')
        .then(response => response.data);

    //public register = (body : any) => this.instance.post('register', { user : body } )
    public register = (body : any) => this.instance.post('register', body )
        .then(response => response.data);

    public passwordResetRequest = (body : any) => this.instance.post('password/reset', body )
        .then(response => response.data);

    public resetPassword = (token : string, password : string) => this.instance.patch('password/reset', {token: token, password: password})
        .then(response => response.data);

    public activateUser = (token : string) => this.instance.patch('register', {token: token})
        .then(response => response.data);

    public fetchAuthConfig = () => this.instance.get('config')
        .then(response => response.data);

    //public transmitCode = (code : string) => this.instance.post('login', {code: code, redirect_uri:'http://localhost:8080/bim-profsrv/'} )
    //public transmitCode = (code : string, session_state : string) => this.instance.post('login', {code: code, session_state: session_state} )
    public transmitCode = (code : string, session_state : string, redirect_uri : string) =>
        this.instance.post('login', {
            code: code, 
            session_state: session_state, 
            redirect_uri: redirect_uri
        })
        .then(response => response.data);

    public refreshToken = (refreshToken : string) =>
        this.instance.post('refresh', {
            refresh_token: refreshToken,
        })
        .then(response => response.data);

}