import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as de from './de-ch.json'
import * as en from './en-us.json'
import * as fr from './fr-ch.json'
import * as it from './it-ch.json'

Vue.use(VueI18n)

const messages = {
    'de-ch': {
    ...de
    },
    'fr-ch': {
    ...fr
    },
    'it-ch': {
    ...it
    },
    'en-us': {
    ...en
    }
}

const detDefLoc = function() {
    let res = 'de-ch';
    const ourLoc = localStorage.getItem("bps-user-locale");
    if (ourLoc) {
        console.log(`Set the i18n locale to our value ${ourLoc} (from localStorage bps-user-locale).`);
        res = ourLoc;
    } else {
        const browserLoc = window.navigator.language;
        if (browserLoc && 1 < browserLoc.length && browserLoc.length < 8) {
            const lc = browserLoc.toLowerCase();
            if (lc == 'de' || lc.startsWith('de-')) {
                res = 'de-ch';
            } else if (lc == 'fr' || lc.startsWith('fr-')) {
                res = 'fr-ch';
            } else if (lc == 'it' || lc.startsWith('it-')) {
                res = 'it-ch';
            } else if (lc == 'en' || lc.startsWith('en-')) {
                res = 'en-us';
            }
            console.log(`Set the i18n locale to ${res} according to browsers default value ${browserLoc} (from window.navigator.language).`);
        }
    }
    return res;
}

export default new VueI18n({
    locale: detDefLoc(),
    messages,
})
