import { HttpClient } from '@/utils/HttpClient';
import { Project } from '@/types/Project';
import { ProjectRole } from '@/types/ProjectRole';
import { RoleCode } from '@/types/RoleCode';
import Vue from 'vue';

export class ProjectsApi extends HttpClient {
    public constructor() {
        super(process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_PROFILE_SUFFIX);
    }

    public fetchProjects = () => this.instance
    .get<Project[]>('projects')
    .then(response => response.data);

    public fetchProjectsPlus = () => this.instance
    .get<Project[]>('projects?statistics=true')
    .then(response => response.data)

    public fetchTemplates = () => this.instance
    .get<Project[]>('projects')
    .then(response => response.data.filter(function(project : Project) {
            return project.is_template === true;
        })
    );

    public fetchProject = (projectId: number) => this.instance
    .get<Project>(`projects/${projectId}` )
    .then(response => {
        return response.data;
    });

    public fetchProjectWithProcesses = (projectId: string) => this.instance
    .get<Project>(`projects/${projectId}?statistics=true&nested=processes`)
    .then(response => {
        return response.data;
    });

    public fetchProjectWithPhases = (projectId: string) => this.instance
    .get<Project>(`projects/${projectId}?statistics=true&nested=phases`)
    .then(response => {
        return response.data;
    });

    public fetchProjectFull = (projectId: string) => this.instance
    .get<Project>(`projects/${projectId}?statistics=true&nested=phases-processes`)
    .then(response => {
        //console.log("fetchProjectWithProcesses", res);
        return response.data;
    });

    public copyProject = (body: Project, projectId : number, msgCompleted: string) => {
        //const { pro_org_name, ...toBeCreated } = body;

        const toBeCreated = { 
            pro_name: body.pro_name, 
            pro_desc: body.pro_desc, 
            pro_pers: body.pro_pers, 
            pro_org_id: body.pro_org_id,
            pro_isc_id: body.pro_isc_id,
            //pro_pro_id: body.pro_pro_id, 
            //pro_id: body.pro_pro_id, 
            pro_pha_nested: body.pro_pha_nested,
            pro_type_code: body.pro_type_code,
            pro_access_all: body.pro_access_all || false
        }

        //return this.instance.post('projects/copy', toBeCreated)
        return this.instance.post(`projects/${projectId}/copy`, toBeCreated)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public editProject = (body: Project, msgCompleted: string) => {
        //console.log("Api project edit", body);
        //spread the body to another variable without pro_id and
        //invalid fields (ES9 Object Rest Operator)
        const { pro_id, status, type, team_size, pro_act_count, pro_change_date, pro_change_user, 
        pro_create_date, pro_create_user, pro_elm_cls_count, pro_elm_typ_count, pro_isc_name, pro_isc_version, pro_org_name, pro_prc_count, 
        pro_prc_nested, pro_pty_cls_count, pro_pty_typ_count, pro_rop_count, pro_mst_count, pro_pha_count, pro_pha_nested, pro_pers, 
        pro_pro_name, ...edited } = body;
        //const edited = {"pro_name": body.pro_name, "pro_org_id": body.pro_org_id};
        //console.log("edited", edited);
        return this.instance.patch(`projects/${body.pro_id}`, edited)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public deleteProject = (projectId: number, msgCompleted: string) => this.instance.delete(`projects/${projectId.toString()}` )
    .then(response => {
        Vue.$toast.success(msgCompleted);
        return response.data;
    });


    public fetchRoles = (projectId: number) => this.instance.get<ProjectRole[]>(`roles_project?rop_pro_id=${projectId}`)
    .then(response => response.data);

    public fetchAllProjectRoles = () => this.instance.get<ProjectRole[]>(`roles_project`)
    .then(response => response.data);

    public fetchAllRoleCodes = () => this.instance.get<RoleCode[]>('codes?cod_col_name=role_project')
    .then(response => response.data);

    public createProjectRole = (body: Object, msgCompleted: string) => this.instance.post('roles_project', body)
    .then(response => {
        Vue.$toast.success(msgCompleted);
        return response.data;
    });

    public editProjectRole = (body: ProjectRole, msgCompleted: string) => {
        //console.log("Api project edit", body);
        //spread the body to another variable without pro_id and
        //invalid fields (ES9 Object Rest Operator)
        const {rop_role_code_code, rop_role_code_name, rop_create_date, rop_pro_name, rop_create_user, rop_change_user, rop_change_date, ...edited } = body;
        //const edited = {"pro_name": body.pro_name, "pro_org_id": body.pro_org_id};
        //console.log("edited", edited);
        return this.instance.patch(`roles_project/${body.rop_id}`, edited)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public deleteProjectRole = (projectRoleId: number, msgCompleted: string) => this.instance.delete(`roles_project/${projectRoleId}` )
    .then(response => {
        Vue.$toast.success(msgCompleted);
        return response.data;
    });
}

