
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'BpsToastCacheRefresh',
    props: {
        latestHash: {
            type: String,
            required: true,
        }
    },
    components: {
    }
})
/**
 * Accordion to display nested content.
 */
export default class BpsToastCacheRefresh extends Vue {

    forceCacheRefresh() {
        //we replace the value of the current version with the actual version.
        localStorage.setItem('currentVersion', this.$props.latestHash);
        this.$emit('close-toast');
        window.location.reload();
    }
}
