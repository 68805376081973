import { HttpClient } from '@/utils/HttpClient';
import { Process } from '@/types/Process';
import Vue from 'vue';

export class ProcessesApi extends HttpClient {
    public constructor() {
        super(process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_PROFILE_SUFFIX);
    }

    public fetchProcesses = () => 
    this.instance.get<Process[]>('processes')
    .then(response => response.data);

    public fetchProjectProcesses = (projectId: number) => 
    this.instance.get<Process[]>(`processes?prc_pro_id=${projectId}`)
    .then(response => response.data);

    public fetchProjectProcessesWithActivities = (projectId: number) => 
    this.instance.get<Process[]>(`processes?prc_pro_id=${projectId}&nested=activities`)
    .then(response => response.data);

    public fetchProcess = (processId: string) => 
    this.instance.get<Process>(`processes/${processId}?nested=activities` )
    .then(response => {
        return response.data;
    });

    public editProcess = (body: Process, msgCompleted: string) => {
        //console.log("Api project edit", body);
        //spread the body to another variable without pro_id and
        //invalid fields (ES9 Object Rest Operator)
        const {prc_create_date, prc_pro_name, prc_id,prc_create_user, prc_change_user, prc_change_date, prc_act_nested, prc_mst_name, 
            prc_org_id, prc_pro_id, prc_act_count, prc_rop_count, ...edited } = body;
        //console.log("edited", edited);
        return this.instance.patch(`processes/${body.prc_id}`, edited)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public createProcess = (body: Process, msgCompleted: string) => {
        const {prc_change_date, prc_change_user, prc_create_date, prc_create_user, prc_id, prc_pro_id, prc_mst_name, prc_org_id, 
            act_count, overlap_phase, phase, prc_act_count, prc_act_nested, prc_pro_name, prc_rop_count, role_count, tags, ...toBeCreated } = body;     
        return this.instance.post('processes', toBeCreated)
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public copyProcess = (body: Process, dstMstId : number, msgCompleted: string) => {
        /* const {prc_change_date, prc_change_user, prc_create_date, prc_create_user, prc_id, prc_pro_id, prc_mst_name, prc_org_id, 
            act_count, overlap_phase, phase, prc_act_count, prc_act_nested, prc_pro_name, role_count, tags, ...toBeCopied } = body; */     
        return this.instance.post(`processes/${body.prc_id}/copy`, {prc_name: body.prc_name, prc_mst_id: dstMstId})
        .then(response => {
            Vue.$toast.success(msgCompleted);
            return response.data;
        });
    }

    public deleteProcess = (processId: number, msgCompleted: string) => this.instance.delete(`processes/${processId.toString()}` )
    .then(response => {
        Vue.$toast.success(msgCompleted);
        return response.data;
    });

    // public createProcessSingular = (body: Process, msgCompleted: string) => {
    //     return this.instance.post('processes', body)
    //     .then(response => {
    //         Vue.$toast.success(msgCompleted);
    //         return response.data;
    //     });
    // }

    public resequenceProcesses = (requests: Array<{prc_id: number, prc_seq: number}>) => {
        const httpRequests = new Array();
        requests.forEach((process:{prc_id: number, prc_seq: number}) => {
            httpRequests.push(this.instance.patch(`processes/${process.prc_id}`, {prc_seq: process.prc_seq}));
        });
        return Promise.all(requests);
    }

    public checkDuplicateProcessName = (mst_id: number, prc_name: string) => {
        const processName = encodeURIComponent(prc_name);
        return this.instance.get<Process[]>(`processes?prc_mst_id=${mst_id}&prc_name=${processName}`)
        .then(response => {
            return response.data;
        });
    }
}






