import { HttpClient } from '@/utils/HttpClient';
import { User } from '@/types/User';
import Vue from 'vue';

export class UserApi extends HttpClient {
    public constructor() {
        super(process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_USER_SUFFIX);
    }

    public fetchUsers = () => this.instance
    .get<User[]>(`users` )
    .then(response => response.data);

    public fetchUser = (UserId: string) => this.instance
    .get<User>(`users/${UserId}` )
    .then(response => response.data);

    public fetchUserPermissions = (UserId: string) => this.instance
    .get<User>(`users/${UserId}?partial=true` )
    .then(response => response.data);

    public fetchUserByEmail = (emailAddress: string) => this.instance
    .get<User[]>(`users?usr_email=` + encodeURIComponent(emailAddress) )
    .then(response => response.data);

    public createUser = (body: User) => {
        //spread the body to another variable without usr_id and
        //other invalid fields (ES9 Object Rest Operator)
        const { usr_create_date, usr_change_date, usr_create_user, usr_change_user, usr_image, usr_org_name, usr_id, usr_org_id, ...created } = body;
        return this.instance.post(`users`, created)
        .then(response => response.data);
    }

    // public editUser = (body: User, msgCompleted: string) => {
    // //spread the body to another variable without usr_id and
    // //other invalid fields (ES9 Object Rest Operator)
    // const { usr_create_date, usr_change_date, usr_create_user, usr_change_user, usr_image, 
    //     usr_org_name, usr_id, usr_app_admin, usr_org_admin, usr_org_pl, usr_org_emp, 
    //     usr_pro_admin, usr_pro_read, usr_pro_write, usr_org_id, usr_app_conn, usr_login_date, 
    //     usr_login_ip, usr_org_pers, usr_org_pers_id, usr_org_pers_name, ...edited } = body;
        
    // return this.instance.patch(`users/${body.usr_id}`, edited)
    //     .then(response => {
    //         Vue.$toast.success(msgCompleted);
    //         return response.data;
    //     })
    //     .catch(response => {
    //         Vue.$toast.error(response.data.message);
    //     });
    // }

    public deleteUser = (UserId: number) => this.instance
    .delete(`users/${UserId}` )
    .then(response => response.data);

    public editImageURL = (payload : {usr_id: number, url: string}) => {
        const preparedPayload = {usr_image_url: payload.url};
        return this.instance.patch(`users/${payload.usr_id}/img`, preparedPayload)
        .then(response => response.data);
    }

}
