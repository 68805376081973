import './filters/TextFilters.ts';
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import { NavigationGuardNext, Route } from 'vue-router';
import Toast, { POSITION, TYPE } from "vue-toastification";

import App from './App.vue';
import Axios from 'axios';
import BpsToastCacheRefresh from '@/components/general/BpsToastCacheRefresh.vue';
import { StatusRetriever } from '@/utils/StatusRetriever';
import { UserApi } from './services/user.api';
import Vue from 'vue';
import logger from './utils/logger';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.use(logger);



const options = {
    position: POSITION.BOTTOM_RIGHT,
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true,
    toastDefaults: {
        // ToastOptions object for each type of toast
        [TYPE.ERROR]: {
            timeout: 6000,
            //showCloseButtonOnHover: true
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
            closeButton: false,
        }    
    }
};

Vue.use(Toast, options);

//import customLogger from './utils/custom-logger';
//Vue.use(customLogger);

//Vue.config.productionTip = true;
//Vue.use(require('vue-moment'));

Vue.config.errorHandler = function (err, vm, info) {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    logger.error('[Global Error Handler]: Error in ' + info + ': ' + err);
}

/**
 * Prevent logging already processed unhandled rejection in console
 */
window.addEventListener('unhandledrejection', event => {
    //console.log('Unhandled Rejection ['+ event.type +']: ', event);
    event.preventDefault();
    event.stopPropagation();
    /* if (event.reason && event.reason.ignoreUnhandledRejection) {
        event.preventDefault();
    } */
    /*else {
        logger.error('['+ event.type +']: ' + event.reason);
        event.preventDefault();
        event.stopPropagation();
    }*/
});
window.onunhandledrejection = (event : PromiseRejectionEvent) => {
    event.preventDefault();
    event.stopPropagation();
    //console.log('onunhandledrejection', event);
}

window.setInterval(function() {
    // Remind that auth.modules.ts and HttpClient.ts are also involved in 
    // the state of the localStorage fields used here.
    const refreshToken = localStorage.getItem("refresh_token");
    // console.log(`Interval refreshToken ..${refreshToken?.slice(-40)}`);
    if (!refreshToken) return;
    const isRefreshing = localStorage.getItem('refresh_attempt');
    if (isRefreshing) return;
    const expiresAt : number = Number(localStorage.getItem('expires_at')!.valueOf());
    const lastUserActivity : number = Number(localStorage.getItem('last_user_activity')!.valueOf());
    const REFRESH_MARGIN = 20000; // 20 seconds
    const USER_INACTIVITY_MAX = 1800000; // 30 mins
    // const USER_INACTIVITY_MAX = 420000; // 7 min (just one auto refresh)
    // If the user is inactiv for more than USER_INACTIVITY_MAX, the auto refresh stops.
    const dNow = Date.now();
    if (dNow < lastUserActivity + USER_INACTIVITY_MAX && expiresAt < dNow + REFRESH_MARGIN) {
        localStorage.setItem('refresh_attempt', dNow.toString());
        const refreshEndpointUrl = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_AUTH_SUFFIX + 'refresh';
        console.log(`About to refresh the token ..${refreshToken?.slice(-40)}.`);
        Axios.post(refreshEndpointUrl, {
            refresh_token: refreshToken,
        }).then(response => {
            // console.log('refreshed data', JSON.stringify(response.data));
            const token = response.data.token.access_token;
            localStorage.setItem('token', token);
            // HttpClient will take it from localStorage.getItem('token') and put it 
            // into config.headers['Authorization'] as "Bearer" from now.
            if(response.data.token.refresh_token != null) {
                localStorage.setItem('refresh_token', response.data.token.refresh_token);
            }
            localStorage.setItem('user', JSON.stringify(response.data.usr));
            const expiration = Date.now() + (response.data.token.expires_in.valueOf() * 1000)
            localStorage.setItem('expires_at', expiration.toString());
            localStorage.removeItem('refresh_attempt');
            console.log(`Refreshed. New access token: ..${token?.slice(-40)}`);
            // TODO return something?
        }).catch(error => {
            console.log(`Refresh error ${error}`);
        }).finally(() => {
            localStorage.removeItem('refresh_attempt');
        });
    }
}, 10000);

// we check that the frontend version running in the browser is the latest version available on the web server
async function checkVersion() {
    //first we retrieve the current version from the LocalStorage
    const currentVersion = localStorage.getItem('currentVersion');
    //then we define headers to ensure that the coming fetch retrieves a non-cached version of index.html
    const headers = new Headers();
    headers.append('pragma', 'no-cache');
    headers.append('cache-control', 'no-cache');
    //we make the options object that will contain these headers
    const options = {
        method: 'GET',
        headers: headers
    }
    //we create the actual request
    const request = new Request('/bim-profsrv/index.html');
    //then we fetch the index.html file
    //const latestIndexResponse = await fetch('/bim-profsrv/index.html');
    const latestIndexResponse = await fetch(request, options);
    //then we convert to body of the file to text
    const latestIndexFile = await latestIndexResponse.text();
    //then we located the hash of the main js file as constructed by Webpack
    const locateHash = latestIndexFile.match(/bps\.app\.(?<hash>.*)\.js"\>/);
    //if there is a match and the hash could be extracted
    if(locateHash!=undefined && locateHash.groups != undefined) {
        const latestHash = locateHash.groups.hash.toString();
        console.log("latestVersion",latestHash);
        //we first check if we have a previous hash to compare it with.
        if(currentVersion === null) {
            //if we don't we just set an item with this value in the LocalStorage. 
            //This should happen only once at the first load of the application.
            localStorage.setItem('currentVersion', latestHash);
        }
        //if there is a current version already defined, we compare it with the one we just extracted from the file index.html
        else if(currentVersion !== latestHash) {
            console.log("new version detected", currentVersion, latestHash);
            //if the hashes don't match, we display an invite for the user to update/refresh their cache.
            Vue.$toast({component: BpsToastCacheRefresh, props: {latestHash: latestHash} }, {type: TYPE.SUCCESS, position: POSITION.BOTTOM_CENTER, timeout: false, icon: false, id: "updateInvite", toastClassName: "bps-neutral-toast",});
        }
    }
}

async function fetchAuthConfig(to : Route) {
    //const api = new AuthApi();
    //api.fetchAuthConfig()

    const config_endpoint = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_AUTH_SUFFIX + 'config';
    
    Axios.get(config_endpoint)
    .then(response => {
        // we override response.authorization_endpoint when working from localhost
        //const actual_location = window.location.origin + router.options.base;
        
        //const actual_location = window.location.origin + window.location.pathname;
        //const actual_location = window.location.origin + router.options.base + to.path;
        //const actual_location = window.location.origin + router.options.base;
        const redirect_uri = window.location.origin + router.options.base + to.path.replace('/', '');

        //const actual_location = window.location.origin + window.location.pathname;
        const corrected_redirect_uri = response.data.authorization_endpoint.replace(/redirect_uri.*/, "redirect_uri="+redirect_uri);
        //if the authorization_endpoint was to be retrieved as a fresh value again, it is probably because 
        //it is the first time that the user is using the application since the switch to TrustID
        //localStorage.clear();
        //localStorage.setItem('redirect_uri', redirect_uri);
        localStorage.setItem('authorization_endpoint', corrected_redirect_uri);
        localStorage.setItem('end_session_endpoint', response.data.end_session_endpoint);
        localStorage.setItem('registration_location', response.data.trustid_see_register);
        localStorage.setItem('password_reset_location', response.data.trustid_see_password);
        localStorage.setItem('account_edit_location', response.data.trustid_see_profile);
        //window.location.href = corrected_redirect_uri;
        /* router.push({
            name: 'login'
        }) */
    })
}

async function transmitCodeToApi(code:string, session_state:string, redirect_uri: string, to: Route, next : NavigationGuardNext) {

    const login_endpoint = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_AUTH_SUFFIX + 'login';

    const response = await Axios.post(login_endpoint, {
        code: code,
        session_state: session_state,
        redirect_uri: redirect_uri,
    })
    .catch((error) => {
        localStorage.removeItem('code');
        localStorage.removeItem('session_state');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        //just in case that flag is still there in LocalStorage although there is no valid reason for it to be.
        localStorage.removeItem('refresh_attempt');
        fetchAuthConfig(to);
        //if the error is about missing the CRB client role then we need to force the logout on TrustID
        //to keep the user out of an infinite loop of login => error => login etc...
        if(error.response.data.internal_code == 'TRUSTID_NO_CRB_KUNDE_ROLE') {
            const token_id = error.response.data.details.id_token;
            const end_session_endpoint = localStorage.getItem('end_session_endpoint') || error.response.data.details.end_session_endpoint;
            const redirect_uri = window.location.origin + router.options.base + 'logout/no-crb-kunde-role';
            const logout_url = end_session_endpoint + "?id_token_hint="+ token_id +"&post_logout_redirect_uri=" + redirect_uri;
            //redirect to TrustID automatic logout link
            window.location.href = logout_url;
        }
        else {
            router.push({
                name: 'login',
                //params: { error: `${error.response.data.message} - ${error.response.data.details}`}
                params: { error: error.response.data.internal_code }
            });
        }
    })

    //console.log("Transmitted TrustID code from LocalStorage", response)
    store.dispatch('login' , {
        token: response.data.token.access_token, 
        id_token: response.data.token.id_token, 
        refresh_token: response.data.token.refresh_token, 
        user: response.data.usr, 
        expires_in: response.data.token.expires_in,
        refresh_expires_in: response.data.token.refresh_expires_in
    })
    .then(() => {
        if(to.name == 'logout' || to.name == 'logout-no-crb-kunde-role' || to.name == 'login') {
            //clear the query to avoid displaying the code parameter in the address bar
            next({
                name: 'project-list',
                query: {}
            })
        } 
        else {
            // proceed to the maintenance page as initially intended
            if(to.path != null) {
                //clear the query to avoid displaying the code parameter in the address bar
                next({
                    path: to.path,
                    query: {}
                });
            }
            else {
                next();
            }
        }
        //push to the last visited page if defined or back to the homepage
        /* next({
            path: to.query.redirect? to.query.redirect.toString() : '/'
        }); */
    })
}

//adding a fail-safe catch for when a Webpack chunk files cannot be loaded anymore because a newer version has been deployed.
router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        checkVersion();
    }
})

router.beforeEach((to, from, next) => {
    console.log('beforeEachMaintenance', to.path, store.getters.maintenanceModeActive);

    /* if(to.name != 'login') {
        localStorage.setItem('redirect_uri', window.location.origin + window.location.pathname)
    } */

    // we override response.authorization_endpoint when working from localhost
    //localStorage.setItem('frontend_base_url', window.location.origin + window.location.pathname);
    //localStorage.setItem('frontend_base_url', window.location.origin + router.options.base + to.path);
    //localStorage.setItem('frontend_base_url', window.location.origin + router.options.base);
    //console.log("Destination", to)
    let authorization_endpoint = localStorage.getItem('authorization_endpoint');
    if(authorization_endpoint != null) {
        if(to.name != 'login') {
            //authorization_endpoint = authorization_endpoint.replace(/redirect_uri.*/, "redirect_uri="+ window.location.origin + window.location.pathname);
            const redirect_uri = window.location.origin + router.options.base + to.path.replace('/', '');
            authorization_endpoint = authorization_endpoint.replace(/redirect_uri.*/, "redirect_uri="+ redirect_uri);
            localStorage.setItem('authorization_endpoint', authorization_endpoint);
            localStorage.setItem('redirect_uri', redirect_uri);
        }
    }
    else {
        fetchAuthConfig(to);
    }

    checkVersion();

    if(to.name != 'maintenance') {
        const api = new StatusRetriever();
        api.fetchStatus()
        .then(res => {
            if(typeof res.default_project_id !== 'undefined' 
            && res.default_project_id !== null 
            && store.getters.defaultProjectId != res.default_project_id) {
                //console.log("Default Project ID", res.default_project_id);
                store.dispatch('setDefaultProjectId', res.default_project_id);
            }

            // we compare here the version of the API with the version expected by the frontend
            if(res.api_version != process.env.VUE_APP_API_VERSION  && to.path !== '/maintenance') {
                store.dispatch('setMaintenanceMode', true);
                /* next({
                    name: 'maintenance'
                }); */
                router.push({
                    name: 'maintenance'
                }).catch(() => {});
            }
            /* else if(res.frontend_version != undefined && res.frontend_version != process.env.VUE_APP_FRONTEND_VERSION) {
                console.info("!!!! DETECTED DIFFERENT FRONTEND VERSION !!!!");
                Vue.$toast(BpsToastCacheRefresh, {type: TYPE.SUCCESS, position: POSITION.BOTTOM_CENTER, timeout: false, icon: false, id: "updateInvite"});
                next();
            } */
            else {
                next();
            }
        })
        .catch(error => {
            if (503 === error.response.status || 404 === error.response.status) {
                store.dispatch('setMaintenanceMode', true);
                /* next({
                    name: 'maintenance'
                }); */
                router.push({
                    name: 'maintenance'
                }).catch(() => {});
            }
        });
    }
    else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    const localUser = localStorage.getItem('user');
    //const authorization_endpoint = localStorage.getItem('authorization_endpoint');
    //const redirect_uri = localStorage.getItem('frontend_base_url');
    //const code = localStorage.getItem('code');
    //const session_state = localStorage.getItem('session_state');
    /* console.log("process.env.THOMAS_TOKEN", process.env.THOMAS_TOKEN);
    if(process.env.THOMAS_TOKEN === 'ok') {
        console.log("LOCAL DEVELOPMENT MODE !!!");
    } */

    // we check if the user is coming back from the SSO login page with a code and a session_state
    if(to.query.session_state != undefined && to.query.code != undefined) {
        //console.log("Received code and session_state in query-string -> storing them in LocalStorage");
        const query_code = to.query.code.toString();
        localStorage.setItem('code', query_code);
        const query_session_state = to.query.session_state.toString();
        localStorage.setItem('session_state', query_session_state);
        const redirect_uri = localStorage.getItem("redirect_uri")!;
        transmitCodeToApi(query_code, query_session_state, redirect_uri, to, next);
    }
    // we check if the route is under the license requirement and if the user is licensed
    else if(to.matched.some(record => record.meta.licenseRequired)) {
        if(store.getters.isLicensed) {
            next();
        }
        else {
            console.log("License required");
            // TODO i18n 'usr_lq_license_required_hint'
            Vue.$toast("Sie benötigen eine gültige Lizenz für den Zugriff auf diese Seite", {type: TYPE.WARNING, position: POSITION.BOTTOM_CENTER, id: "licenseRequired"});
        }
    }
    else if(store.getters.isLoggedIn && localUser != null) {
        console.log("About to fetch User Permissions");
        //there is a bug here when the access token is expired and the application resubmit the request after refreshing it
        //the currently attempted solution is to prevent the original call to happen if the token is expired
        const expiration_date = localStorage.getItem('expires_at');
        const now = Date.now();
        if(expiration_date && now < +expiration_date) {
            //if the user is logged in, we call a special util class PermissionsRefresher that is independent 
            //of HttpClient (hence independent from the router itself to avoid circular reference)
            //to get the latest permissions and update the Vuex store with them
            const user = JSON.parse(localUser);
            const api = new UserApi();
            api.fetchUserPermissions(user.usr_id)
            .then(res => {
                //console.log("permissions refresher response", res);
                store.dispatch('setUserPermissions', res);
                next();
            });
        }
        else {
            next();
        }
    }
    else if(to.matched.some(record => record.meta.notAuthRequired)) {
        next();
    }
    else {
        store.dispatch('clearLogin');

        if(to.name != 'login') {
            const redirect_uri = window.location.origin + router.options.base + to.path.replace('/', '');
            localStorage.setItem('redirect_uri', redirect_uri);
        }

        next({
            name: 'login',
            params: {
                message: 'Um auf diese Seite zugreifen zu können, müssen Sie authentifiziert sein. Bitte melden Sie sich mit Ihrem Login an.'
            }
        });
    }
});


new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');