
import { Component, Vue } from 'vue-property-decorator';
import { UserApi } from '@/services/user.api';
import { User } from '@/types/User';
import BpsUserPlanLabel from '@/components/user/BpsUserPlanLabel.vue';


@Component({
  components: {
    BpsUserPlanLabel
  },
  props: {
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fold'
  }
})
/**
 * User widget comnponent with a foldable menu linking to user profil and logout action.
 * @vue-data {boolean} isExpanded - flag that defines if the menu is displayed or not.
 * @vue-data {User} currentUser - The User object displayed by the component.
 */
export default class BpsUserWidget extends Vue {
    isExpanded : boolean = false;
    currentUser : User = new User();

    /**
     * Computed property for the CSS class to show depending on the component's state.
     */
    get expanderClass() {
        return this.isExpanded ? "expanded" : "";
    }

    /**
     * Computed property for whether the user is logged-in.
     */
    get isLoggedIn() { 
        return this.$store.getters.isLoggedIn;
    }

    /**
     * Computed property for the User's first name.
     */
    get firstname() { 
        return this.$store.getters.firstname;
    }

    /**
     * Computed property for the User's last name.
     */
    get lastname() { 
        return this.$store.getters.lastname;
    }

    /**
     * Computed property for the User's Organisation's name.
     */
    get orgname() { 
        return this.$store.getters.orgName;
    }

    /**
     * Computed property for the User's email address.
     */
    get user_email() { 
        return this.$store.getters.email;
    }

    /**
     * Computed property for the User ID.
     */
    get user_id() { 
        return this.$store.getters.userId;
    }

    /**
     * Computed property for the User license status.
     */
    get is_user_licensed() { 
        return this.$store.getters.isLicensed;
    }

    /**
     * Computed property for the User's profile picture.
     */
    get profile_pic() {
        if(this.$store.getters.userImage != null) {
            return 'data:image/jpeg;base64,'+this.$store.getters.userImage;
        }
        else {
            return "";
        }
    }

    /**
     * Switch to set the current locale.
     */
    switchLocale(tgtLoc:string) {
        this.isExpanded = false;
        this.$i18n.locale = tgtLoc;
        localStorage.setItem("bps-user-locale", tgtLoc);
        const doc = document.querySelector("html");
        if (doc) {
            doc.setAttribute("lang", tgtLoc);
        }
    }

    canSwitchLocale(loc:string) {
        return this.$i18n.locale != loc;
    }

    /**
     * The component fetch the data for the logged-in User when mounted.
     */
    mounted() {
        if(this.isLoggedIn) {
            console.log("BpsUserWidget mounted", this.isLoggedIn)
            this.fetchUser();
        }
    }

    /**
     * Switch component state between expanded or folded.
     */
    toggleExpand() {
        this.isExpanded = !this.isExpanded;
    }

    /**
     * Folds the component regardless of previous state.
     */
    fold() {
        this.isExpanded = false;
    }

    /**
     * Method that handle the logout and calls the corresponding Vuex store action.
     * Then redirects the user to the login screen.
     */
    logout() {
        if(this.isLoggedIn==true) {
            const logout_endpoint = localStorage.getItem('end_session_endpoint');
            const redirect_uri = window.location.origin + this.$router.options.base + 'logout'
            const id_token_hint = localStorage.getItem('id_token')
            if(logout_endpoint != null && redirect_uri != null && id_token_hint != null) {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('id_token');
                localStorage.removeItem('refresh_token');
                //just in case that flag is still there in LocalStorage although there is no valid reason for it to be.
                localStorage.removeItem('refresh_attempt');
                localStorage.removeItem('code');
                localStorage.removeItem('session_state');
                localStorage.removeItem('password_reset_location');
                localStorage.removeItem('account_edit_location');
                //important to include the id token hint for the logout to be effective
                window.location.href = logout_endpoint + "?id_token_hint="+ id_token_hint +"&post_logout_redirect_uri=" + redirect_uri;
            }
            //console.log("Logout sent");
            /* api.logout().then(res => {
                this.$store.dispatch('logout')
                .then(() => {
                    //this.$router.push('/login')
                    const logout_endpoint = localStorage.getItem('end_session_endpoint');
                    if(logout_endpoint != null) {
                        window.location.href = logout_endpoint;
                    }
                })
                .catch(err => {
                    this.$log.error('[Navigation Error] :' + err);
                });
                //the logout action could be triggered from the route that we are pushing to.
                //the empty catch is here to prevent a redundant Navigation error
                //https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
            })
            .catch(error => {
                if(error.response) {
                    //console.log("Authentication error", error.response);
                    if(error.response.data.code == 401) {
                        //TODO implement token refresh mechanism
                        //console.log("Authentication error 401", error.response.data.message);
                        this.$store.dispatch('logout')
                        .then(() => {
                            this.$router.push('/login')
                            .catch(err => {
                                this.$log.error('[Navigation Error] :' + err);
                            });
                            //the logout action could be triggered from the route that we are pushing to.
                            //the empty catch is here to prevent a redundant Navigation error
                            //https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
                        })
                    }
                }
            }); */
        }
    }

    goToSupport() {

    }

    /**
     * API calls to retrieve the data related to logged-in user.
     */
    fetchUser() {
        if(this.user_id != undefined || this.$store.getters.userId != undefined) {
            const userApi = new UserApi();
            //console.log("Fetching user");
            userApi.fetchUser(this.user_id).then( (user:User) => {
                this.currentUser = user;
                this.$store.dispatch('setUser', this.currentUser);
            });
        }
    }
}
