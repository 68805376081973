import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
//import store from '@/store';
import { User } from '@/types/User';

export class PermissionsRefresher {
    protected readonly instance: AxiosInstance;

    public constructor() {
        this.instance = axios.create({
            baseURL: process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_USER_SUFFIX
        });
        this._initializeRequestInterceptor();
        this._initializeResponseInterceptor();
    }

    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(
            this._handleRequest,
            this._handleError,
        );
    };

    private _handleRequest = (config: AxiosRequestConfig) => {
        //const token = store.getters.sessionToken;
        const token = localStorage.getItem('token');
        if(token != undefined && token != '') {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    };



    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            //this._handleError,
            //() => { console.log("Response error handler in interceptor  ")}
        );
    };

    private _handleResponse = ({ data }: AxiosResponse) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log("HttpClient Response Interceptor", data);
        return data;
    };

    protected _handleError = (error: any) => {
        //store.dispatch('setErrorAppMessage', error.response.data.message);
        return Promise.reject(error.response.data.message);
    };

    public fetchUserPermissions = (UserId: string) => this.instance
    .get<User>(`users/${UserId}?partial=true` )
    .then(response => {
        //console.log("Service FetchUserPermissions", response);
        return response;
    });

}