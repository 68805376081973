import Vue from 'vue';

export function capitalize (value: string) {
  if (!value) return ''
  const sentence = value.toLowerCase().split(" ");
  for(let i = 0; i< sentence.length; i++){
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
}

export function uppercase (value: string) {
  if (!value) return ''; 
  return value.toUpperCase()
}

export function comparable (value: String) {
  if (!value) return '';
  let result = value.toLowerCase();
  // Ü, ü     \u00dc, \u00fc
  // Ä, ä     \u00c4, \u00e4
  // Ö, ö     \u00d6, \u00f6
  // ß        \u00df
  result = result.replace(/\u00fc/g, "ue");
  result = result.replace(/\u00e4/g, "ae");
  result = result.replace(/\u00f6/g, "oe");
  result = result.replace(/\u00df/g, "ss");
  return result;
}

export function highlight(word : string, query : string) {
    if(word != null && query != null && word != '' && query != '') {
        const check = new RegExp(query, "igm");
        return word.toString().replace(check, function(matchedText){
            return ('<strong>' + matchedText + '</strong>');
        });
    }
    else {
        return word;
    }
}

export function formatOrgName(orgName : string) : string {
  if(orgName != undefined) {
    if(orgName.includes('@')) {
        return 'Persönliche Organisation';
    }
    else return orgName;
  }
  else return '';
}

const filters = { capitalize, uppercase, formatOrgName, highlight };

Object.keys( filters ).forEach( key => {
  Vue.filter(key, (filters as any)[key])
});

export default filters;