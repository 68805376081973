/*import { createLogger, StringifyObjectsHook, LoggerHook, LogEvent, LoggerOptions } from 'vue-logger-plugin'
import axios from 'axios'

const ServerLogHook: LoggerHook = {
  run (event: LogEvent) {
      console.log("ServerLogHook");
      //axios.post('/log', { severity: event.level, data: event.argumentArray })
  }
}

const options: LoggerOptions = {
    enabled: true,    // {boolean} enable/disable logging
    level: 'debug',   // {string} the logging level (one of: debug, info, warn, error)
    beforeHooks: [
        StringifyObjectsHook
    ],
    afterHooks: [
        ServerLogHook
    ]
}

const logger = createLogger(options);

export default logger*/

import VueLogger from 'vue-logger-plugin';
import { StringifyObjectsHook, LoggerOptions } from 'vue-logger-plugin'
import axios from 'axios';

declare module 'vue/types/vue' {
  interface Vue {
    $log: VueLogger;
  }
}

const ServerLogHook = {
  run (event : {level: string, argumentArray: string[]}) {
    axios.post(process.env.VUE_APP_LOG_SERVER_BASE_URL+'/api/logger', { severity: event.level, data: event.argumentArray })
  }
}

const options : LoggerOptions = {
  enabled: true,
  level: 'log',
  beforeHooks: [StringifyObjectsHook],
  afterHooks: [ServerLogHook]
};

// export logger with applied options
export default new VueLogger(options);