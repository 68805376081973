import { HttpClient } from '@/utils/HttpClient';
import { Phase } from '@/types/Phase';

export class PhasesApi extends HttpClient {
    public constructor() {
        super(process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_PROFILE_SUFFIX);
    }

    public fetchAllPhases = () => this.instance
    .get<Phase[]>(`phases`)
    .then(response => response.data);

    public fetchProjectPhases = (projectId : number) => this.instance
    .get<Phase[]>(`phases?pha_pro_id=${projectId}`)
    .then(response => response.data);

    public fetchMultiplePhases = (phaseIds : number[]) => {
        const requests = new Array();
        phaseIds.forEach( (id:number) => {
            requests.push( this.instance.get(`phases/`+id.toString()) );
        })
        return Promise.all(requests);
    }

    public createPhase = (body: Phase) => {
        const {pha_change_date, pha_change_user, pha_create_date, pha_create_user, pha_id, pha_mst_nested, 
            pha_phase_code_code, pha_phase_code_name, pha_phase_code_seq, pha_pro_name, ...toBeCreated } = body;
        return this.instance.post('phases', toBeCreated)
        .then(response => response.data);
    }

    public createPhaseMultiple = (phases : Phase[], projectId:number) => {
        const requests = new Array();
        phases.forEach( (phase:Phase) => {
            const {pha_change_date, pha_change_user, pha_create_date, pha_create_user, pha_id, pha_mst_nested, ...phaseToBeCreated} = phase;
            phaseToBeCreated.pha_pro_id = projectId;
            requests.push(this.instance.post('phases', phaseToBeCreated));
        })
        return Promise.all(requests);
    }

    public deletePhase = (phaseId: number) => this.instance.delete(`phases/${phaseId.toString()}` )
    .then(response => {
        return response.data;
    });

    /*
    public deletePhaseMultiple = (phaseIds : number[]) => {
        let requests = new Array();
        phaseIds.forEach( (id:number) => {
            requests.push( this.instance.delete(`phases/`+id.toString()) );
        })
        return Promise.all(requests);
    }
    */

    public deletePhaseMultiple = (phases : Phase[]) => {
        const requests = new Array();
        phases.forEach( (phase:Phase) => {
            requests.push( this.instance.delete(`phases/`+phase.pha_id.toString()) );
        })
        return Promise.all(requests);
    }
}


