//import Vue from 'vue';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Project } from '@/types/Project';
import { Phase } from '@/types/Phase';
import { Milestone } from '@/types/Milestone';
import { Process } from '@/types/Process';
import { MilestonesApi } from '@/services/milestones.api';
import { ProjectsApi } from '@/services/projects.api';


//import { Stone } from '@/types/Stone';
//import { Well } from '@/types/Well';

@Module//({ namespaced: true, name: 'auth' })
export default class ProjectModule extends VuexModule {
    workingProject : Project = new Project(); //Project currently being created
    workingMilestones : Milestone[] = new Array<Milestone>();
    selectedProject : Project = new Project(); //Project selected for browsing its details
    phases : Array<Phase> = new Array<Phase>();
    milestones : Array<Milestone> = new Array<Milestone>();
    processes : Array<Process> = new Array<Process>();

    get currentProject() : Project {
        return this.selectedProject;
    }

    get currentProjectMilestones() : Milestone[] {
        let milestones = new Array<Milestone>();
        if(this.selectedProject.pro_pha_nested != undefined) {
            this.selectedProject.pro_pha_nested.forEach( (p:Phase) =>{
                if(p.pha_mst_nested != undefined) {
                    milestones = milestones.concat(p.pha_mst_nested);
                }
            });
        }
        return milestones;
    }

    get currentProjectProcesses() : Process[] {
        let processes = new Array<Process>();
        if(this.selectedProject.pro_pha_nested != undefined) {
            this.selectedProject.pro_pha_nested.forEach( (p:Phase) =>{
                if(p.pha_mst_nested != undefined) {
                    p.pha_mst_nested.forEach( (m:Milestone) => {
                        if(m.mst_prc_nested != undefined) {
                            processes = processes.concat(m.mst_prc_nested);
                        }
                    })
                }
            });
        }
        return processes;
    }

    get currentProjectPhases() : Phase[] {
        if(this.selectedProject.pro_pha_nested != undefined) {
            return this.selectedProject.pro_pha_nested;
        }
        else {
            return new Array<Phase>();
        }
    }

    get projectIdDefined() : boolean {
        return this.selectedProject.pro_id != undefined;
    }

    get newProject() : Project {
        return this.workingProject;
    }

    /*
    get currentProcesses() : Process[] {
        return this.workingProject.pro_prc_nested;
    }

    get currentPhases() : Phase[] {
        return this.workingProject.pro_pha_nested || [];
    }

    get currentMilestones() : Milestone[] {
        return this.workingMilestones;
    }
    */

    get selectedProcessesCount() : number {
        return this.selectedProject.pro_prc_nested.length;
    }

    /*--------MUTATIONS----------*/

    @Mutation
    SET_PROJECT_NAME(pro_name : string){
        this.workingProject.pro_name = pro_name;
    }

    @Mutation
    SET_CURRENT_PROJECT(project : Project){
        this.selectedProject = project;
    }

    @Mutation
    SET_CURRENT_PROJECT_NAME(pro_name : string){
        this.selectedProject.pro_name = pro_name;
    }

    @Mutation
    SET_CURRENT_PROJECT_DESCRIPTION(pro_desc : string){
        this.selectedProject.pro_desc = pro_desc;
    }

    @Mutation
    UNSET_CURRENT_PROJECT() {
        this.selectedProject = new Project();
    }

    @Mutation
    RESET_WORKING_PROJECT() {
        this.workingProject = new Project();
        this.workingMilestones = new Array<Milestone>();
    }


    @Mutation
    SET_CURRENTPROJECT_MILESTONES(milestones : Milestone[]) {
        if(this.selectedProject.pro_pha_nested != undefined) {
            //for each Phase of the selectedProject
            this.selectedProject.pro_pha_nested.forEach((p:Phase)=>{
                const stones = milestones.filter( m => m.mst_pha_id == p.pha_id);
                //we reset the Array
                p.pha_mst_nested = new Array<Milestone>();
                //then we push each Milestone in it, in the right order
                stones.forEach( (newStone:Milestone) => {
                    if(p.pha_mst_nested != undefined) {
                        p.pha_mst_nested.push(newStone);
                    }
                    //if the milestone seq number has changed
                    /*
                    if(newStone.mst_seq != mst_seq) {
                        newStone.mst_seq = mst_seq;
                        const api = new MilestonesApi();
                        api.editMilestone(newStone);
                    }
                    mst_seq++;
                    */
                });
                //console.log("SET_CURRENTPROJECT_MILESTONES", p.pha_mst_nested);
                //for each Milestone, we look it up in pha_mst_nested
                /*stones.forEach( (newStone:Milestone) => {
                    if(p.pha_mst_nested != undefined) {
                        const oldStone = p.pha_mst_nested.find((st:Milestone) => st.mst_id == newStone.mst_id);
                        console.log("Found old previous Milestone in Phase", p.pha_name, oldStone);
                        if(oldStone != undefined) {
                            let idx = p.pha_mst_nested.indexOf(oldStone);
                            console.log("Found index of old previous Milestone", idx, newStone);
                            //then we replace it with the new Milestone
                            idx != -1 ? p.pha_mst_nested.splice(idx,1,newStone) : true ;
                        }
                    }

                });*/
                //console.log("SET_CURRENTPROJECT_MILESTONES",stones);
                /*if(p.pha_mst_nested != undefined) {
                    //p.pha_mst_nested.splice(0,p.pha_mst_nested.length, stones);
                    //p.pha_mst_nested = stones;
                    //Vue.set(p, 'pha_mst_nested', stones);
                }*/
            });
        }
    }

    @Mutation
    SET_WORKING_PHASES(phases : Phase[]){
        phases.forEach( (ph:Phase) => {
            ph.pha_mst_nested = new Array<Milestone>();
        })
        this.workingProject.pro_pha_nested = phases;
    }

    @Mutation
    SET_NEW_PROJECT_ORGANISATION(org : { pro_org_id: number, pro_org_name: string }){
        this.workingProject.pro_org_id = org.pro_org_id;
        this.workingProject.pro_org_name = org.pro_org_name;
    }

    @Mutation
    SETUP_NEW_PROJECT_REFERENCE(projectId: number) {
        this.workingProject.pro_pro_id = projectId;
    }

    //Adds a Process to a Project being built
    @Mutation
    ADD_PROCESS(prc : Process){
        const existing_process = this.workingProject.pro_prc_nested.find(process  => prc.prc_id === process.prc_id);
        if(existing_process === undefined) {
            this.workingProject.pro_prc_nested.push(prc);
        }
    }

    //Add a Process to a Project already built (while modifying it)
    @Mutation
    ADD_PROCESS_TO_MILESTONE(payload : {prc : Process, mst : Milestone}){
        if(this.selectedProject.pro_pha_nested != undefined) {
            //const phases = this.context.getters['currentProject'];
            console.log("before", payload.mst.mst_pha_id);
            const phase = this.selectedProject.pro_pha_nested.find((p:Phase) => p.pha_id == payload.mst.mst_pha_id);
            if(phase != undefined && phase.pha_mst_nested != undefined) {
                const milestone = phase.pha_mst_nested.find((m:Milestone) => {
                    return m.mst_id == payload.mst.mst_id;
                });

                if(milestone != undefined && milestone.mst_prc_nested != undefined) {
                    milestone.mst_prc_nested.push(payload.prc);
                }
            }
            //console.log("after");
        }
    }

    @Mutation
    ADD_MILESTONE(milestone : Milestone){
        //find the corresponding Phase in the current Project
        if(this.workingProject.pro_pha_nested != undefined) {
            const phase = this.workingProject.pro_pha_nested
                .find( (ph:Phase) => ph.pha_id == milestone.mst_pha_id && ph.pha_name == milestone.mst_pha_name);
            if(phase != undefined && phase.pha_mst_nested != undefined) {
                //then check if the Milestone exists already or if a Milestone with the same name already exists in this Phase
                const stone = phase.pha_mst_nested.find( (mst:Milestone) => 
                    (mst.mst_id == milestone.mst_id && mst.mst_name == milestone.mst_name)
                    || mst.mst_name == milestone.mst_name
                );
                if(stone == undefined) {
                    //if it's not there already, then we can add it
                    phase.pha_mst_nested.push(milestone);
                }
            }
        }

        //also adding it to the store's Milestones array
        const existing_milestone = this.workingMilestones.find((stone:Milestone)  => {
            return (
                //it's an existing Milestone that has a mst_id set and this id is already in the Milestone list
                (Number(milestone.mst_id.valueOf()) >= 0 && stone.mst_id === milestone.mst_id) || 
                //OR there is already a Milestone with the same name in this Phase
                (stone.mst_name === milestone.mst_name && stone.mst_pha_id === milestone.mst_pha_id)
            )}
        );
        if(existing_milestone === undefined) {
            this.workingMilestones.push(milestone);
        }
        else {
            //there is already a Milestone with that name in that Phase
            //TODO emit an app message that a Milestone with the same name already exists in this Phase
        }
    }

    @Mutation
    REMOVE_PROCESS(prc : Process){
        //We first identify the Milestone which was nesting the Process
        const stone = this.workingMilestones.find( (mst : Milestone) => mst.mst_id == prc.prc_mst_id);
        //console.log("Mutation REMOVE_PROCESS found corresponding Milestone", stone, prc.prc_mst_id );
        if(stone != undefined && stone.mst_prc_nested != undefined) {
            const mst_prc = stone.mst_prc_nested.find( (stone_prc : Process) => stone_prc.prc_id == prc.prc_id);
            //if found, we search for the corresponding Process in its nested Processes
            if(mst_prc != undefined) {
                //if found, we remove the Process from the Milestone's nested Processes
                const idx = stone.mst_prc_nested.indexOf(mst_prc);
                idx != -1 ? stone.mst_prc_nested.splice(idx,1) : true ;
            }

            //Secondly, we add in the Project being built, inside the corresponding Milestone, which itself is in its respective Phase
            //we find the right Phase
            const phase = this.workingProject.pro_pha_nested!.find( (ph:Phase) => ph.pha_name == stone.mst_pha_name);
            if(phase != undefined && phase.pha_mst_nested != undefined) {
                //inside the Phase we find the right Milestone
                const mst = phase.pha_mst_nested.find((ms:Milestone)=> ms.mst_id == stone.mst_id);
                if(mst != undefined && mst.mst_prc_nested != undefined) {
                    //inside the Milestone we check that the Process is there
                    const pr = mst.mst_prc_nested.find( (p:Process) => p.prc_id == prc.prc_id);
                    if(pr != undefined) {
                        const idx = mst.mst_prc_nested.indexOf(pr);
                        //if it's there and we know it index, we remove it from the Milestone's nested Processes
                        idx != -1 ? mst.mst_prc_nested.splice(idx,1) : true ;
                    }
                }
            }
        }

        const existing_process = this.workingProject.pro_prc_nested.find(process  => prc.prc_id === process.prc_id);
        if(existing_process != undefined) {
            const index = this.workingProject.pro_prc_nested.indexOf(existing_process);
            index != -1 ? this.workingProject.pro_prc_nested.splice(index,1) : true ;
        }
    }

    @Mutation
    REMOVE_PROCESS_FROM_CURRENTPROJECT(prc : Process) {
        let pha_id = 0;
        if(this.selectedProject != undefined && this.selectedProject.pro_pha_nested != undefined) {
            this.selectedProject.pro_pha_nested.forEach((p:Phase) => {
                if(p.pha_mst_nested != undefined) {
                    if(p.pha_mst_nested.find((m:Milestone) => m.mst_id === prc.prc_mst_id) != undefined) {
                        pha_id = p.pha_id;
                    }
                }
            })
            if(pha_id != 0) {
                const phase = this.selectedProject.pro_pha_nested.find((p:Phase) => p.pha_id == pha_id);
                if(phase != undefined && phase.pha_mst_nested != undefined) {
                    const milestone = phase.pha_mst_nested.find((m:Milestone)=> m.mst_id === prc.prc_mst_id);
                    if(milestone != undefined && milestone.mst_prc_nested != undefined) {
                        const process = milestone.mst_prc_nested.find((p:Process) => p.prc_id === prc.prc_id);
                        if(process != undefined) {
                            const idx = milestone.mst_prc_nested.indexOf(process);
                            idx != -1 ? milestone.mst_prc_nested.splice(idx,1) : true ;
                        }
                    }
                }
            }
        }
    }

    @Mutation
    REMOVE_MILESTONE(milestone : Milestone){
        //First we remove the Milestone from the global Milestones array
        const existing_milestone = this.workingMilestones.find((stone:Milestone)  => stone.mst_id === milestone.mst_id && stone.mst_name === milestone.mst_name);
        if(existing_milestone != undefined) {
            const index = this.workingMilestones.indexOf(existing_milestone);
            index != -1 ? this.workingMilestones.splice(index,1) : true ;
        }

        //Secondly we remove the nested Processes from the global Processes array
        if(milestone.mst_prc_nested != undefined) {
            milestone.mst_prc_nested.forEach( (prc:Process) => {
                const proc = this.workingProject.pro_prc_nested.find( (p:Process) => p.prc_id == prc.prc_id);
                if(proc != undefined) {
                    const idx = this.workingProject.pro_prc_nested.indexOf(proc);
                    idx != -1 ? this.workingProject.pro_prc_nested.splice(idx,1) : true ;
                }
            });
        }

        //Finally, we remove the Milestone from the Project being built
        const phase = this.workingProject.pro_pha_nested!.find( (ph:Phase) => ph.pha_id == milestone.mst_pha_id);
        if(phase != undefined && phase.pha_mst_nested != undefined) {
            //we find by both ID and name because newly created Milestones share the same ID -1
            const ms = phase.pha_mst_nested.find((m:Milestone) => m.mst_id == milestone.mst_id && m.mst_name == milestone.mst_name);
            if(ms != undefined) {
                const idx = phase.pha_mst_nested.indexOf(ms);
                idx != -1 ? phase.pha_mst_nested.splice(idx,1) : true ;
            }
        }
    }

    /* @Mutation
    SET_MILESTONES(milestones : Milestone[]){
        //console.log('SET_MILESTONES',milestones);
        //replace the whole Milestone array with a new one
        this.workingMilestones = milestones;
        this.workingProject.pro_prc_nested = new Array<Process>();

        //for each Milestone, we add it to the new Project being built
        milestones.forEach((milestone : Milestone) => {
            //check if the Milestone is already part of the workingProject in the corresponding Phase
            //find the right Phase by name because the Phase ID might not match
            const phase = this.workingProject.pro_pha_nested!.find( (pha:Phase) => pha.pha_name == milestone.mst_pha_name);
            //if the Phase is found and it has Milestones nested
            if(phase != undefined && phase.pha_mst_nested != undefined) {
                const ms = phase.pha_mst_nested.find( (m:Milestone) => m.mst_id == milestone.mst_id);
                //we check if the Milestone is already nested in this Phase, and if not we push it there.
                if(ms == undefined) {
                    //we align the Phase ID of the Milestone with the Phase ID of the Reference Project's Phase
                    milestone.mst_pha_id = phase.pha_id;
                    phase.pha_mst_nested.push(milestone);
                }
            }

            //then we dispatch Processes to the global Process array of the Project being built
            if(milestone.mst_prc_nested != undefined) {
                milestone.mst_prc_nested.forEach((prc : Process) => {
                    //check if the process already exists (using name and id because new Processes share the same id -1)
                    const process = this.workingProject.pro_prc_nested.find( (pr:Process) => pr.prc_id == prc.prc_id && pr.prc_name == prc.prc_name);
                    if(process==undefined) {
                        this.workingProject.pro_prc_nested.push(prc);
                    }
                });
            }

            //then we replace each of them to the global Milestones array
            const ms = this.workingMilestones.find((ms:Milestone) => ms.mst_id == milestone.mst_id);
            if(ms != undefined) {
                let idx = this.workingMilestones.indexOf(ms);
                if(idx != -1) {
                    this.workingMilestones.splice(idx, 1, milestone);
                }
            }
        })
    } */

    @Mutation
    SET_MILESTONE_PROCESSES(payload : {milestone:Milestone, processes:Process[]}) {
        //for each Processes in the passed Milestone
        payload.processes.forEach( (prc:Process) => {
            const existingStone = this.workingMilestones
            .find( (mst:Milestone) => mst.mst_id == payload.milestone.mst_id && mst.mst_name == payload.milestone.mst_name);

            if(existingStone != undefined && existingStone.mst_prc_nested != undefined) {
                //First, we add the Process to the global Milestones array
                //console.log("Mutation SET_MILESTONE_PROCESSES found corresponding Milestone", existingStone);
                const proc = existingStone.mst_prc_nested.find( (pr:Process) => pr.prc_id == prc.prc_id && pr.prc_name == prc.prc_name);
                //if the Process is not already included in the Milestone corresponding to the passed Milestone in the store, then we add it
                if(proc == undefined) {
                    //we make sure that the Process prc_mst_id is up-to-date
                    prc.prc_mst_id = existingStone.mst_id;
                    //we add the Process to the Milestone's nested Processes
                    existingStone.mst_prc_nested.push(prc);
                }

                //Secondly, we add in the Project being built, inside the corresponding Milestone, which itself is in its respective Phase
                //we find the right Phase
                const phase = this.workingProject.pro_pha_nested!.find( (ph:Phase) => ph.pha_name == existingStone.mst_pha_name);
                if(phase != undefined && phase.pha_mst_nested != undefined) {
                    //inside the Phase we find the right Milestone
                    const mst = phase.pha_mst_nested.find((ms:Milestone)=> ms.mst_id == existingStone.mst_id);
                    if(mst != undefined && mst.mst_prc_nested != undefined) {
                        //inside the Milestone we check that the Process is not there already
                        const pr = mst.mst_prc_nested.find( (p:Process) => p.prc_id == prc.prc_id);
                        if(pr == undefined) {
                            //if it's not already there, we push it in the Milestone's nested Processes
                            mst.mst_prc_nested.push(prc);
                        }
                    }
                }

                //if the Process doesn't exist already in the whole Project being built, then we add it there too (for duplicate checking purpose)
                if(this.workingProject.pro_prc_nested.find( (pr:Process) => pr.prc_id == prc.prc_id && pr.prc_name == prc.prc_name) == undefined) {
                    this.workingProject.pro_prc_nested.push(prc);
                }
            }
        });
    }

    @Mutation
    SET_MILESTONE_SEQ(payload : {milestone:Milestone, newSeq:number}) {
        /*let stone = this.context.getters.currentMilestones.find( (mst:Milestone) => mst.mst_id = payload.milestone.mst_id);
        if(stone != undefined) {
            stone.mst_seq = payload.newSeq;
        }*/
        payload.milestone.mst_seq = payload.newSeq;
    }

    @Mutation
    REORDER_MILESTONE(payload : any) {
        const phase = this.selectedProject.pro_pha_nested!.find( (p:Phase) => p.pha_id == payload.pha_id);
        if(phase != undefined && phase.pha_mst_nested != undefined) {
            //move element from index to index
            phase.pha_mst_nested.splice(payload.newIndex, 0, phase.pha_mst_nested.splice(payload.oldIndex,1)[0]);

            //resequence the Milestones by updating their mst_seq
            phase.pha_mst_nested.forEach( (m:Milestone , index) => {
                m.mst_seq = index;
                //console.log("Milestone changed", m.mst_name, m.mst_seq);
            });
        }

        //console.log("Store Mutation - REORDER_MILESTONE", payload, phase);
        //this.splice(to, 0, this.splice(from, 1)[0]);
    }

    @Mutation
    REMOVE_MILESTONE_FROM_CURRENT_PHASE(payload : any) {
        const phase = this.selectedProject.pro_pha_nested!.find( (p:Phase) => p.pha_id == payload.pha_id);
        if(phase != undefined && phase.pha_mst_nested != undefined) {
            //remove element at old index
            phase.pha_mst_nested.splice(payload.oldIndex, 1);

            //resequence the remaining Milestones by updating their mst_seq
            phase.pha_mst_nested.forEach( (m:Milestone , index) => {
                m.mst_seq = index;
                //console.log("Milestone changed", m.mst_name, m.mst_seq);
            });
        }
        //console.log("Mutation Remove Milestone From Current Phase", payload);
    }

    @Mutation
    ADD_MILESTONE_TO_CURRENT_PHASE(payload : any) {
        //console.log('ADD_MILESTONE_TO_CURRENT_PHASE',payload);
        const phase = this.selectedProject.pro_pha_nested!.find( (p:Phase) => p.pha_id == payload.pha_id);
        if(phase != undefined && phase.pha_mst_nested != undefined) {
            payload.milestone.mst_pha_id = phase.pha_id;
            payload.milestone.mst_pha_name = phase.pha_name;
            //add element at new index
            phase.pha_mst_nested.splice(payload.newIndex, 0, payload.milestone);

            //resequence the Milestones by updating their mst_seq
            phase.pha_mst_nested.forEach( (m:Milestone , index) => {
                m.mst_seq = index;
                //console.log("Milestone changed", m.mst_name, m.mst_seq);
            });
        }
        //console.log("Mutation Add Milestone To Current Phase", payload);
    }

    /* @Mutation
    REFRESH_PROCESS(payload : any) {
        //const editedProject = {... this.selectedProject};
        //editedProject.pro_pha_nested[payload.pha_idx].pha_mst_nested[payload.mst_idx].mst_prc_nested.splice()
        //Vue.set(editedProject.pro_pha_nested[payload.pha_idx].pha_mst_nested[payload.mst_idx].mst_prc_nested, payload.prc_idx, payload.process);
        //this.selectedProject = {...this.selectedProject};
        //Vue.set(this.selectedProject, 0, this.selectedProject);
        //this.selectedProject.pro_pha_nested[payload.pha_idx].pha_mst_nested[payload.mst_idx].mst_prc_nested.splice(payload.prc_idx, 1, payload.process);
    } */

    /**
     * --------ACTIONS--------
     * */

    @Action
    refreshProcess(process : Process) {
        const processToRefresh = this.currentProjectProcesses.find((prc:Process) => prc.prc_id === process.prc_id);
        if(processToRefresh != undefined) {
            const milestone = this.currentProjectMilestones.find((mst : Milestone) => mst.mst_id === processToRefresh.prc_mst_id);

            if(milestone != undefined) {
                const phase = this.currentProjectPhases.find((pha:Phase) => pha.pha_id === milestone.mst_pha_id);
        
                if(processToRefresh && phase && milestone) {
                    const pha_idx = this.currentProject.pro_pha_nested.indexOf(phase);
                    const mst_idx = this.currentProject.pro_pha_nested[pha_idx].pha_mst_nested.indexOf(milestone);
                    const prc_idx = this.currentProject.pro_pha_nested[pha_idx].pha_mst_nested[mst_idx].mst_prc_nested.indexOf(processToRefresh);
                    if(prc_idx!=1) {
                        //const payload = {pha_idx: pha_idx, mst_idx: mst_idx, prc_idx: prc_idx, process: process};
                        //console.log("action refreshProcess", payload);
                        //this.context.commit('REFRESH_PROCESS', payload);
                        //this.currentProject.pro_pha_nested[pha_idx].pha_mst_nested[mst_idx].mst_prc_nested.splice(prc_idx, 1, process);
                    }
                }
            }
        }
    }

    @Action
    getProject(projectId : number) {
        const api = new ProjectsApi();
        api.fetchProject(projectId)
        .then( res => {
            this.context.commit('SET_CURRENT_PROJECT', res);
        })
    }

    @Action
    getProjectFull(projectId : number) {
        const api = new ProjectsApi();
        api.fetchProjectFull(projectId.toString())
        .then( res => {
            const project = res;
            const milestonesApi = new MilestonesApi();
            milestonesApi.fetchMilestones(projectId)
            .then( res => {
                const payload = {project: project, milestones: res};
                this.context.dispatch('setCurrentProjectFull', payload);
            })
        })
    }

    @Action
    setProjectName(pro_name : string) {
        this.context.commit('SET_PROJECT_NAME', pro_name);
    }

    @Action
    setCurrentProjectName(pro_name : string) {
        this.context.commit('SET_CURRENT_PROJECT_NAME', pro_name);
    }

    @Action
    setCurrentProjectDescription(pro_desc : string) {
        this.context.commit('SET_CURRENT_PROJECT_DESCRIPTION', pro_desc);
    }

    @Action
    setCurrentProject(project : Project) {
        this.context.commit('SET_CURRENT_PROJECT', project);
    }

    @Action
    setCurrentProjectFull(payload: {project : Project, milestones: Milestone[]}) {
        if(payload.project.pro_pha_nested != undefined) {
            payload.project.pro_pha_nested.forEach( (p:Phase) => {
                const stones : Milestone[] = payload.milestones.filter( m => m.mst_pha_id == p.pha_id);
                p.pha_mst_nested = stones;
            });
        }
        this.context.commit('SET_CURRENT_PROJECT', payload.project);
        //important to refresh the gantt chart overtview after a change
        this.context.commit('SET_PROCESSES', payload.project.pro_prc_nested);
    }

    @Action
    setWorkingProjectPhases(phases : Phase[]) {
        this.context.commit('SET_WORKING_PHASES', phases);
    }

    @Action
    setCurrentProjectMilestones(milestones : Milestone[]) {
        this.context.commit('SET_CURRENTPROJECT_MILESTONES', milestones);
    }

    @Action
    unsetCurrentProject() {
        this.context.commit('UNSET_CURRENT_PROJECT');
    }

    @Action
    resetWorkingProject() {
        this.context.commit('RESET_WORKING_PROJECT');
    }

    @Action
    setProcess(prc : Process) {
        const existing_process = this.workingProject.pro_prc_nested.find(process  => prc.prc_id === process.prc_id);
        if(existing_process === undefined) {
            this.context.commit('ADD_PROCESS', prc);
        }
        else {
            this.context.commit('REMOVE_PROCESS', prc);
        }
    }

    @Action
    addProcess(prc : Process) {
        this.context.commit('ADD_PROCESS', prc);
    }

    @Action({rawError: true})
    addProcessToMilestone(payload : {prc : Process, mst : Milestone}) {
        this.context.commit("ADD_PROCESS_TO_MILESTONE", payload);
    }

    @Action
    removeProcess(prc : Process) {
        this.context.commit('REMOVE_PROCESS', prc);
    }

    @Action
    removeProcessFromCurrentProject(prc : Process) {
        this.context.commit('REMOVE_PROCESS_FROM_CURRENTPROJECT', prc);
    }

    @Action
    addMilestone(stone : Milestone) {
        this.context.commit('ADD_MILESTONE', stone);
    }

    @Action
    setMilestones(stones : Milestone[]) {
        this.context.commit('SET_MILESTONES', stones);
    }

    @Action({ rawError: true })
    setMilestoneProcesses(payload : {milestone:Milestone, processes:Process[]}) {
        //stone : Milestone, processes : Process[]
        this.context.commit('SET_MILESTONE_PROCESSES', payload);
    }

    @Action
    removeMilestone(stone : Milestone) {
        this.context.commit('REMOVE_MILESTONE', stone);
    }

    @Action
    setNewProjectOrganisation(org : { pro_org_id: number, pro_org_name: string }) {
        this.context.commit('SET_NEW_PROJECT_ORGANISATION', org);
    }

    @Action
    setupNewProjectReference(projectId: number) {
        this.context.commit('SETUP_NEW_PROJECT_REFERENCE', projectId);
    }

    @Action
    MilestoneChangeOrder(payload : any) {
        this.context.commit('REORDER_MILESTONE', payload);
    }

    @Action({rawError: true})
    ResequenceCurrentProjectMilestones() {
        for(let i=0; i<this.context.getters.currentProjectMilestones.length; i++) {
            const prevSeq = this.context.getters.currentProjectMilestones[i].mst_seq;
            if(prevSeq != i+1) {
                const newSeq = i+1;
                const payload = {milestone: this.context.getters.currentProjectMilestones[i], newSeq: newSeq};
                this.context.commit('SET_MILESTONE_SEQ', payload);
                //this.updateMilestoneSeq(milestones[i].mst_id, milestones[i].mst_seq);
            }
        }
    }

    @Action
    MilestoneRemoved(payload : any) {
        this.context.commit('REMOVE_MILESTONE_FROM_CURRENT_PHASE', payload);
    }

    @Action
    MilestoneAdded(payload : any) {
        this.context.commit('ADD_MILESTONE_TO_CURRENT_PHASE', payload);
    }
}