import { render, staticRenderFns } from "./BpsUserWidget.vue?vue&type=template&id=d02ad328&scoped=true&"
import script from "./BpsUserWidget.vue?vue&type=script&lang=ts&"
export * from "./BpsUserWidget.vue?vue&type=script&lang=ts&"
import style0 from "./BpsUserWidget.vue?vue&type=style&index=0&id=d02ad328&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d02ad328",
  null
  
)

export default component.exports