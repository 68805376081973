import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import { AppStates } from '@/utils/AppStates'
import { MessageStatus } from '@/utils/MessageStatus'

@Module//({ namespaced: true, name: 'general' })
export default class GeneralModule extends VuexModule {
    appState : AppStates = AppStates.DEFAULT;
    appMessage : string = "";
    appMessageStatus : MessageStatus = MessageStatus.INFO;
    appMessageDisplay : boolean = false;
    maintenanceMode : boolean = false;
    default_project_id : number = -1;

    get currentAppState() : AppStates {
        return this.appState;
    }

    get generalMessage() : string {
        return this.appMessage;
    }

    get messageStatus() : MessageStatus {
        return this.appMessageStatus;
    }

    get messageDisplay() : boolean {
        return this.appMessageDisplay;
    }

    get maintenanceModeActive() : boolean {
        return this.maintenanceMode;
    }

    get defaultProjectId() : number {
        return this.default_project_id;
    }

    @Mutation
    SET_APP_STATE(state : AppStates){
        this.appState = state;
    }

    @Mutation
    SET_APP_MESSAGE(msg : string){
        this.appMessage = msg;
    }

    @Mutation
    SET_APP_INFO_MESSAGE(msg : string){
        this.appMessage = msg;
        this.appMessageStatus = MessageStatus.INFO;
    }

    @Mutation
    SET_APP_LOG_MESSAGE(msg : string){
        this.appMessage = msg;
        this.appMessageStatus = MessageStatus.LOG;
    }

    @Mutation
    SET_APP_DEBUG_MESSAGE(msg : string){
        this.appMessage = msg;
        this.appMessageStatus = MessageStatus.DEBUG;
    }

    @Mutation
    SET_APP_WARNING_MESSAGE(msg : string){
        this.appMessage = msg;
        this.appMessageStatus = MessageStatus.WARNING;
    }

    @Mutation
    SET_APP_ERROR_MESSAGE(msg : string){
        this.appMessage = msg;
        this.appMessageStatus = MessageStatus.ERROR;
    }

    @Mutation
    SET_APP_SUCCESS_MESSAGE(msg : string){
        this.appMessage = msg;
        this.appMessageStatus = MessageStatus.SUCCESS;
    }

    @Mutation
    SET_APP_MESSAGE_STATUS(status : MessageStatus){
        this.appMessageStatus = status;
    }

    @Mutation
    SET_APP_MESSAGE_DISPLAY(flag : boolean){
        this.appMessageDisplay = flag;
    }

    @Mutation
    SET_MAINTENANCE_MODE(flag : boolean){
        this.maintenanceMode = flag;
    }

    @Mutation
    SET_DEFAULT_PROJECT_ID(id : number) {
        this.default_project_id = id;
    }

    @Action
    setcurrentAppState(state : AppStates) {
      this.context.commit('SET_APP_STATE', state);
    }

    @Action
    setcurrentAppMessage(msg : string){
      this.context.commit('SET_APP_MESSAGE', msg);
      this.context.commit('SET_APP_MESSAGE_DISPLAY', true);
    }

    @Action
    setInfoAppMessage(msg : string){
      this.context.commit('SET_APP_INFO_MESSAGE', msg);
      this.context.commit('SET_APP_MESSAGE_DISPLAY', true);
    }

    @Action
    setLogAppMessage(msg : string){
      this.context.commit('SET_APP_LOG_MESSAGE', msg);
      this.context.commit('SET_APP_MESSAGE_DISPLAY', true);
    }

    @Action
    setDebugAppMessage(msg : string){
      this.context.commit('SET_APP_DEBUG_MESSAGE', msg);
      this.context.commit('SET_APP_MESSAGE_DISPLAY', true);
    }

    @Action
    setWarningAppMessage(msg : string){
      this.context.commit('SET_APP_WARNING_MESSAGE', msg);
      this.context.commit('SET_APP_MESSAGE_DISPLAY', true);
    }

    @Action
    setErrorAppMessage(msg : string){
      this.context.commit('SET_APP_ERROR_MESSAGE', msg);
      this.context.commit('SET_APP_MESSAGE_DISPLAY', true);
    }

    @Action
    setSuccessAppMessage(msg : string){
      this.context.commit('SET_APP_SUCCESS_MESSAGE', msg);
      this.context.commit('SET_APP_MESSAGE_DISPLAY', true);
    }

    @Action
    setMessageDisplay(flag : boolean){
        this.context.commit('SET_APP_MESSAGE_DISPLAY', flag);
    }

    @Action
    setMaintenanceMode(flag : boolean){
        this.context.commit('SET_MAINTENANCE_MODE', flag);
    }

    @Action
    setDefaultProjectId(id : number){
        this.context.commit('SET_DEFAULT_PROJECT_ID', id);
    }

    
}