
import { Component, Vue } from 'vue-property-decorator';
import { MessageStatus } from '@/utils/MessageStatus'

@Component({
  props: {
  }
})
/**
 * General toast message display
 */
export default class BpsMessageToaster extends Vue {
    timer : number = 0;
    displayTime : number = 2000;
    status : MessageStatus = MessageStatus.INFO;

    beforeDestroy () {
        clearInterval(this.timer);
    }

    get message() : string {
        return this.$store.getters.generalMessage;
    }

    get statusClass() : string {
        switch(this.status) {
            case(MessageStatus.LOG):
                return 'log';
                break;
            case(MessageStatus.DEBUG):
                return 'debug';
                break;
            case(MessageStatus.WARNING):
                return 'warning';
                break;
            case(MessageStatus.ERROR):
                return 'error';
                break;
            case(MessageStatus.SUCCESS):
                return 'success';
                break;
            case(MessageStatus.INFO):
            default:
                return 'info';
                break;
        }
    }

    get requiresAcknowledgement() : boolean {
        return (this.status==MessageStatus.ERROR || this.status==MessageStatus.WARNING);
    }

    get visible() : boolean {
        const vis : boolean = this.$store.getters.messageDisplay;
        if(vis == true) {
            this.status = this.$store.getters.messageStatus;
            if(this.status != MessageStatus.ERROR && this.status != MessageStatus.WARNING) {
                clearInterval(this.timer);
                this.timer = setInterval(this.hideMessage, this.displayTime);
            }
        }
        return vis;
    }

    hideMessage() {
        clearInterval(this.timer);
        this.$store.dispatch('setMessageDisplay', false);
    }
}
