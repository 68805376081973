import { Route } from 'vue-router';

const routes = [
    {
        path: '/',
        props: true,
        //TODO: replace with the actual homepage or login form
        //component: () => import(/* webpackChunkName: "projects" */ '@/pages/Projects.vue'),
        redirect: '/projects'
    },
    {
        path: '/projects',
        props: {context: 'project-list'},
        name: 'projects',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "projects" */ '@/pages/PageProjects.vue'),
        redirect: {
            name: 'project-list'
        },
        children: [
            {
                path: 'recent',
                props: {context: 'project-list'},
                name: 'projects-recent',
                component: () => import(/* webpackChunkName: "project-list-recent" */ '@/components/project/BpsProjectList.vue'),
            },
            {
                path: 'my-organisation',
                props: {context: 'project-list'},
                name: 'projects-organisation',
                component: () => import(/* webpackChunkName: "project-list-org" */ '@/components/project/BpsProjectList.vue'),
            },
            {
                path: 'public',
                props: {context: 'project-list'},
                name: 'projects-public',
                component: () => import(/* webpackChunkName: "project-list" */ '@/components/project/BpsProjectList.vue'),
            },
            {
                path: 'list',
                props: {context: 'project-list'},
                name: 'project-list',
                component: () => import(/* webpackChunkName: "project-list" */ '@/components/project/BpsProjectList.vue'),
            }
        ]
    },
    {
        path: '/projects/new',
        props: {context: 'project-list'},
        //props: (route: { params: { projectId: number; }; }) => ({ projectId: Number(route.params.projectId) }),
        name: 'project-new',
        component: () => import(/* webpackChunkName: "project-new" */ '@/components/project/BpsProjectNew.vue'),
    },
    {
        path: '/projects/:projectId/new',
        props: true,
        //props: (route: { params: { projectId: number; }; }) => ({ projectId: Number(route.params.projectId) }),
        name: 'project-new-from-template',
        component: () => import(/* webpackChunkName: "project-new" */ '@/components/project/BpsProjectNew.vue'),
    },
    {
        path: '/projects/:projectId',
        //props: (route: Route) => ({ projectId: Number(route.params.projectId) }),
        props: true,
        component: () => import(/* webpackChunkName: "project-details" */ '@/components/project/BpsProjectDetails.vue'),
        redirect: 'dashboard',
        children: [
            {
                path: 'dashboard',
                props: true,
                name: 'project-dashboard',
                component: () => import(/* webpackChunkName: "project-dashboard" */ '@/components/project/BpsProjectDashboard.vue'),
            },
            {
                path: 'processes',
                props: true,
                name: 'project-processes',
                component: () => import(/* webpackChunkName: "project-processes" */ '@/components/process/BpsProcesses.vue'),
                children: [
                    {
                        path: ':processId',
                        props: true,
                        /*props: {
                            default: true,
                            // function mode, more about it below
                            //processId: (route : Route) => Number(route.params.processId)
                          },//(route: { params: { processId: number; }; }) => ({ processId: Number(route.params.processId) }),*/
                        name: 'process-details',
                        component: () => import(/* webpackChunkName: "project-processes" */ '@/components/process/BpsProcesses.vue'),
                    }
                ],
            },
            {
                path: 'roles',
                name: 'project-roles',
                props: true,
                component: () => import(/* webpackChunkName: "project-roles" */ '@/components/project/BpsProjectRoles.vue'),
                children: [
                    {
                        path: ':roleId',
                        props: (route: Route) => ({ roleId: Number(route.params.roleId) }),
                        name: 'role-details',
                        component: () => import(/* webpackChunkName: "project-roles-details" */ '@/components/project/BpsProjectRoles.vue'),
                    }
                ],
            },
            {
                path: 'documents',
                props: true,
                name: 'project-documents',
                component: () => import(/* webpackChunkName: "project-documents" */ '@/components/project/BpsProjectDocuments.vue'),
            },
            {
                path: 'data-catalog',
                props: true,
                name: 'project-data-catalog',
                component: () => import(/* webpackChunkName: "project-data-catalog" */ '@/components/general/BpsDataCatalog.vue'),
                redirect: 'data-catalog/main',
                children: [
                    {
                        path: 'types',
                        props: true,
                        name: 'project-types',
                        component: () => import(/* webpackChunkName: "project-catalog-types" */ '@/components/property/BpsTypes.vue'),
                    },
                    {
                        path: 'main',
                        props: true,
                        name: 'project-data-catalog-main',
                        component: () => import(/* webpackChunkName: "project-fachklassen" */ '@/components/project/BpsProjectDataCatalogWrapper.vue'),
                    },
                    {
                        path: 'value-lists',
                        props: true,
                        name: 'project-value-lists',
                        component: () => import(/* webpackChunkName: "project-catalog-lists" */ '@/components/property/BpsCatalogsList.vue'),
                    },
                    {
                        path: 'custom-properties',
                        props: true,
                        name: 'project-custom-properties',
                        component: () => import(/* webpackChunkName: "project-custom-properties" */ '@/components/property/BpsCustomProperties.vue'),
                    },
                    {
                        path: 'groupings',
                        props: true,
                        name: 'project-groupings',
                        component: () => import(/* webpackChunkName: "project-groupings" */ '@/components/property/BpsGroupings.vue'),
                    },
                    {
                        path: 'classifications',
                        props: true,
                        name: 'project-classifications',
                        component: () => import(/* webpackChunkName: "project-classifications" */ '@/components/property/BpsClassificationsList.vue'),
                    },
                    {
                        path: 'material-lists',
                        props: true,
                        name: 'project-materiallists',
                        component: () => import(/* webpackChunkName: "project-materials" */ '@/components/property/BpsMaterialLists.vue'),
                    },
                ],
            },
            {
                path: 'export',
                props: true,
                name: 'project-export',
                component: () => import(/* webpackChunkName: "project-export" */ '@/components/project/BpsProjectExport.vue'),
            },
            {
                path: 'permissions',
                props: true,
                name: 'project-permissions',
                meta: { licenseRequired: true },
                component: () => import(/* webpackChunkName: "project-permissions" */ '@/components/general/BpsPermissions.vue'),
                redirect: 'permissions/users',
                children: [
                    {
                        path: 'users',
                        props: true,
                        name: 'project-permissions-users',
                        component: () => import(/* webpackChunkName: "project-permissions" */ '@/components/project/BpsProjectUsers.vue'),
                    },
                    {
                        path: 'share',
                        props: true,
                        name: 'project-share',
                        component: () => import(/* webpackChunkName: "project-permissions" */ '@/components/project/BpsProjectShare.vue'),
                    }
                ]
            },
        ]
    },
    {
        path: '/templates',
        props: {context: 'template-list'},
        name: 'templates',
        component: () => import(/* webpackChunkName: "templates" */ '@/pages/PageTemplates.vue'),
        redirect: {
            name: 'template-list'
        },
        children: [
            {
                path: 'recent',
                props: {context: 'template-list'},
                name: 'templates-recent',
                component: () => import(/* webpackChunkName: "templates-list" */ '@/components/project/BpsProjectList.vue'),
            },
            {
                path: 'my-organisation',
                props: {context: 'template-list'},
                name: 'templates-organisation',
                component: () => import(/* webpackChunkName: "templates-list" */ '@/components/project/BpsProjectList.vue'),
            },
            {
                path: 'public',
                props: {context: 'template-list'},
                name: 'templates-public',
                component: () => import(/* webpackChunkName: "templates-list" */ '@/components/project/BpsProjectList.vue'),
            },
            {
                path: 'new',
                props: {context: 'template-list'},
                name: 'template-new',
                component: () => import(/* webpackChunkName: "project-new" */ '@/components/project/BpsProjectNew.vue'),
            },
            {
                path: 'list',
                props: {context: 'template-list'},
                name: 'template-list',
                component: () => import(/* webpackChunkName: "templates-list" */ '@/components/project/BpsProjectList.vue'),
            }
        ]
    },
    {
        path: '/references',
        props: {context: 'reference-list'},
        name: 'references',
        component: () => import(/* webpackChunkName: "references" */ '@/pages/PageReferences.vue'),
        redirect: {
            name: 'reference-list'
        },
        children: [
            {
                path: 'list',
                props: {context: 'reference-list'},
                name: 'reference-list',
                component: () => import(/* webpackChunkName: "references-list" */ '@/components/project/BpsProjectList.vue'),
            }
        ]
    },
    
    {
        path: '/templates/:projectId',
        props: true,
        //props: (route: { params: { projectId: Number; }; }) => ({ projectId: Number(route.params.projectId) }),
        name: 'template-details',
        component: () => import(/* webpackChunkName: "templates-details" */ '@/components/project/BpsTemplateDetails.vue')
    },

    {
        path: '/users',
        props: true,
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/pages/PageUsers.vue'),
        redirect: {
            name: 'user-profile'
        },
        children: [
            {
                path: 'list',
                props: true,
                name: 'users-list',
                component: () => import(/* webpackChunkName: "users-list" */ '@/components/user/BpsUserList.vue'),
            },
            {
                path: 'new',
                props: true,
                name: 'user-creation',
                component: () => import(/* webpackChunkName: "user-new" */ '@/components/user/BpsUserNew.vue'),
            },
            {
                path:'organisation/:orgId',
                props: true,
                name: 'users-org-perm',
                component: () => import(/* webpackChunkName: "user-org-permissions" */ '@/components/organisation/BpsOrganisationUsers.vue'),
            },
            {
                path: 'organisations',
                props: true,
                name: 'organisations-list',
                component: () => import(/* webpackChunkName: "org-list" */ '@/components/organisation/BpsOrganisationList.vue'),
            },
            {
                path: 'new-organisation',
                props: true,
                name: 'organisation-creation',
                component: () => import(/* webpackChunkName: "org-new" */ '@/components/organisation/BpsOrganisationNew.vue'),
            },
            {
                path:'profile',
                /*props: (route: { params: { userId: Number; }; }) => ({ userId: Number(store.getters.userId) }),*/
                props: true,
                name: 'user-profile',
                component: () => import(/* webpackChunkName: "user-profile" */ '@/pages/PageUserProfile.vue'),
                children: [
                    {
                        path:':userId',
                        props: true,
                        component: () => import(/* webpackChunkName: "user-profile" */ '@/pages/PageUserProfile.vue'),
                    }
                ]
            }
        ]
    },
    {
        path: '/about',
        props: true,
        name: 'impressum',
        component: () => import(/* webpackChunkName: "impressum" */ '@/pages/PageImpressum.vue'),
    },
    {
        path: '/login',
        props: true,
        name: 'login',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/PageLogin.vue'),
    },
    {
        path: '/logout',
        props: {logout: true},
        name: 'logout',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/PageLogin.vue'),
    },
    {
        path: '/logout/no-crb-kunde-role',
        props: { error: 'TRUSTID_NO_CRB_KUNDE_ROLE' },
        name: 'logout-no-crb-kunde-role',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "login" */ '@/pages/PageLogin.vue'),
    },
    {
        path: '/register',
        props: true,
        name: 'user-registration',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "register" */ '@/pages/PageRegister.vue'),
    },
    {
        path: '/activate/:tokenId',
        props: true,
        name: 'user-activation',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "activate" */ '@/pages/PageLogin.vue'),
    },
    {
        path: '/password-reset/:tokenId',
        props: true,
        name: 'password-reset',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "password-reset" */ '@/pages/PagePasswordReset.vue'),
    },
    {
        path: '/maintenance',
        props: true,
        name: 'maintenance',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "maintenance" */ '@/pages/PageMaintenance.vue'),
    },
    { 
        path: '/not-authorized',
        name: 'not-authorized',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "not-authorized" */ '@/pages/PageNotAuthorized.vue'),
    },
    { 
        path: '/not-found',
        name: 'not-found',
        meta: { notAuthRequired: true },
        component: () => import(/* webpackChunkName: "not-found" */ '@/pages/PageNotFound.vue'),
    }
];

export default routes;