import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

import { Phase } from '@/types/Phase';
import { Milestone } from '@/types/Milestone';
import { Process } from '@/types/Process';

import { PhasesApi } from '@/services/phases.api';
import { MilestonesApi } from '@/services/milestones.api';
import { ProcessesApi } from '@/services/processes.api';

@Module//({ namespaced: true, name: 'new.project' })
export default class NewProjectModule extends VuexModule {
    phases : Phase[] = new Array<Phase>();
    milestones : Milestone[] = new Array<Milestone>();
    processes : Process[] = new Array<Process>();

    get currentPhases() {
        return this.phases;
    }

    get currentMilestones() {
        return this.milestones;
    }

    get currentProcesses() {
        return this.processes;
    }

    @Action
    setPhases(phases : Phase[]) {
        this.context.commit('SET_PHASES', phases);
    }

    @Action
    setMilestones(milestones : Milestone[]) {
        this.context.commit('SET_MILESTONES', milestones);
    }

    @Action
    setProcesses(processes : Process[]) {
        this.context.commit('SET_PROCESSES', processes);
    }

    @Action
    changeMilestoneOfProcess(payload : {processId: number, milestoneId: number, newIndex: number}) {
        //we create an independent copy of the processes to be modified
        const newState = this.processes.map(obj => ({...obj}));
        //we find the correct process in the list of all processs
        const process = newState.find((process:Process) => process.prc_id == payload.processId);
        //if we find it
        if(process != undefined) {
            const oldIndex = newState.indexOf(process);
            //we change the milestone id of the process to the new milestone.
            process.prc_mst_id = payload.milestoneId;
            if(oldIndex != undefined) {
                //the newIndex is initially calculated while the element is still at its previous position
                //this is why, if we move the element down in the array (ie. to a new index bigger thans the previous index), 
                //we need to account for the fact that the element is still taking a place in the array.
                if(oldIndex<payload.newIndex) {
                    payload.newIndex--;
                }
                //from https://stackoverflow.com/a/5306832
                newState.splice(payload.newIndex, 0, newState.splice(oldIndex, 1)[0]);
            }

            //then we update the store (we could have put this in the callback of the API call below but it would have caused some 
            //rendering issue where the Process still belongs to the preivous Milestone for a split second).
            this.context.commit('SET_PROCESSES', newState);

            //once the process has been replaced with the whole Processes' array, we can patch it on the API
            const api = new ProcessesApi();
            // TODO i18n 'prc_sc_prc_edit_completed'
            api.editProcess(process, 'Prozess erfolgreich bearbeitet.')
            .then(() => {
                //and trigger a resequencing of all the Processes
                this.context.dispatch('resequenceProcesses');
            });
        }
    }

    @Action
    changeProcessOrderInMilestone(payload : {processId: number, milestoneId: number, oldIndex: number, newIndex: number}) {
        this.context.commit('CHANGE_PROCESS_ORDER', payload);
        this.context.dispatch('resequenceProcesses');
    }

    @Action
    changeMilestoneOrder(payload : {milestoneId: number, gardenId: number, oldIndex: number, newIndex: number}) {
        this.context.commit('CHANGE_MILESTONE_ORDER', payload);
        this.context.dispatch('resequenceMilestones');
    }

    @Action
    changePhaseOfMilestone(payload : {milestoneId: number, phaseId: number, newIndex: number}) {
        //we create an independent copy of the Milestones to be modified
        const newState = this.milestones.map(obj => ({...obj}));
        //we find the correct milestone in the list of all milestones
        const milestone = newState.find((milestone:Milestone) => milestone.mst_id == payload.milestoneId);
        //we search for the phase as well, in order to change its name within the Milestone
        const phase = this.phases.find((phase:Phase) => phase.pha_id == payload.phaseId);
        //if we find it
        if(milestone != undefined && phase != undefined) {
            const oldIndex = newState.indexOf(milestone);
            console.log("changePhaseOfMilestone",oldIndex, milestone);
            milestone.mst_pha_id = payload.phaseId;
            milestone.mst_pha_name = phase.pha_name;
            if(oldIndex != undefined) {
                //the newIndex is initially calculated while the element is still at its previous position
                //this is why, if we move the element down in the array (ie. to a new index bigger thans the previous index), 
                //we need to account for the fact that the element is still taking a place in the array.
                if(oldIndex<payload.newIndex) {
                    payload.newIndex--;
                }
                //from https://stackoverflow.com/a/5306832
                newState.splice(payload.newIndex, 0, newState.splice(oldIndex, 1)[0]);
            }

            //then we update the store (we could have put this in the callback of the API call below but it would have caused some 
            //rendering issue where the Milestone still belongs to the previous Phase for a split second).
            this.context.commit('SET_MILESTONES', newState);

            //once the process has been replaced with the whole Processes' array, we can patch it on the API
            const api = new MilestonesApi();
            // TODO i18n 'mst_sc_mst_edit_completed'
            api.editMilestone(milestone, 'Meilenstein erfolgreich bearbeitet.')
            .then(() => {
                //and trigger a resequencing of all the Processes
                this.context.dispatch('resequenceMilestones');
            });
        }
    }

    @Action
    resequenceProcesses() {
        //we make a deep copy of the processes array to compare it later
        const previousState = this.processes.map(obj => ({...obj}));
        //we create another independent copy of the processes to be modified
        const newState = this.processes.map(obj => ({...obj}));

        let milestoneId = -1;
        let counter = 1;
        this.milestones.forEach((milestone:Milestone) => {
            if(milestone.mst_id != milestoneId) {
                milestoneId = milestone.mst_id;
                counter = 1;
            }
            newState.forEach((process:Process) => {
                if(process.prc_mst_id == milestoneId) {
                    process.prc_seq = counter++;
                }
            })
        })

        //here we compare the previous state with the new state and create an object if a patch request is needed.
        const patchRequests = new Array();
        for(let i=0; i<previousState.length; i++) {
            if(previousState[i].prc_id == newState[i].prc_id 
                && previousState[i].prc_seq != newState[i].prc_seq) {
                patchRequests.push({prc_id: newState[i].prc_id, prc_seq: newState[i].prc_seq})
            }
        }
        console.log("resequenceProcesses", patchRequests);
        const api = new ProcessesApi();
        api.resequenceProcesses(patchRequests)
        .then(() => {
            this.context.commit('SET_PROCESSES', newState);
        });
    }

    @Action
    resequenceMilestones() {
        //we make a deep copy of the milestones array to compare it later
        const previousState = this.milestones.map(obj => ({...obj}));
        //we create another independent copy of the milestones to be modified
        const newState = this.milestones.map(obj => ({...obj}));

        let phaseId = -1;
        let counter = 1;
        this.phases.forEach((phase:Phase) => {
            if(phase.pha_id != phaseId) {
                phaseId = phase.pha_id;
                counter = 1;
            }
            newState.forEach((milestone:Milestone) => {
                if(milestone.mst_pha_id == phaseId) {
                    milestone.mst_seq = counter++;
                }
            })
        })

        //here we compare the previous state with the new state and create an object if a patch request is needed.
        const patchRequests = new Array();
        for(let i=0; i<previousState.length; i++) {
            if(previousState[i].mst_id == newState[i].mst_id 
                && previousState[i].mst_seq != newState[i].mst_seq) {
                patchRequests.push({mst_id: newState[i].mst_id, mst_seq: newState[i].mst_seq})
            }
        }
        console.log("resequenceMilestones", patchRequests);
        const api = new MilestonesApi();
        api.resequenceMilestones(patchRequests)
        .then(() => {
            this.context.commit('SET_MILESTONES', newState);
        });
    }

    @Action
    fetchPhases(projectId : number) {
        const api =  new PhasesApi();
        api.fetchProjectPhases(projectId)
        .then(response => this.context.commit('SET_PHASES', response));
    }

    @Action
    fetchMilestones(projectId : number) {
        const api =  new MilestonesApi();
        api.fetchMilestones(projectId)
        .then(response => this.context.commit('SET_MILESTONES', response));
    }

    @Action
    fetchProcesses(projectId : number) {
        const api = new ProcessesApi();
        api.fetchProjectProcesses(projectId)
        .then(response => this.context.commit('SET_PROCESSES', response));
    }
    
    @Mutation
    SET_PHASES(phases : Phase[]) {
        this.phases = phases;
    }
    
    @Mutation
    SET_MILESTONES(milestones : Milestone[]) {
        this.milestones = milestones;
    }
    
    @Mutation
    SET_PROCESSES(processes : Process[]) {
        this.processes = processes;
    }

    @Mutation
    CHANGE_PROCESS_ORDER(payload : {processId: number, milestoneId: number, oldIndex: number, newIndex: number}) {
        //from https://stackoverflow.com/a/5306832
        this.processes.splice(payload.newIndex, 0, this.processes.splice(payload.oldIndex, 1)[0]);
    }

    @Mutation
    CHANGE_MILESTONE_ORDER(payload : {milestoneId: number, phaseId: number, oldIndex: number, newIndex: number}) {
        //from https://stackoverflow.com/a/5306832
        this.milestones.splice(payload.newIndex, 0, this.milestones.splice(payload.oldIndex, 1)[0]);
    }
}
