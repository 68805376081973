
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
  props: {
  }
})
/**
 * Dev widget to switch API URL
 */
export default class BpsDevAPISwitcher extends Vue {
    visible : boolean = false;
    debugString : string = '';

    /**
     * When mounted the component default to the real API and register an event to display environment information.
     */
    mounted() {
        if(localStorage.getItem('apiBaseUrl') == null) {
            this.$store.dispatch('setRealApiMode');
        }
        window.addEventListener("keydown", (event) => {
            if (event.ctrlKey && event.key === 'd') {
                this.toggleVisibility();
            }
        });
    }

    /**
     * Computed property to get the base URL for connection to the backend API.
     */
    get currentBaseUrl() {
        return this.$store.getters.baseUrl;
    }

    /**
     * Computed property to display which API is currently active.
     */
    get currentAPIMode() {
        return this.$store.getters.isMockupEnabled == true ? 'mockup API' : 'real    API';
    }


    /**
     * Computed property to display the current environment of the application based on environment variable.
     */
    get environment() {
        return process.env.NODE_ENV;
    }

    toggleVisibility() {
        this.visible = !this.visible;
    }
}
