
import { Component, Vue } from 'vue-property-decorator';


@Component({
    props: {
        /**
         * @vue-prop {Boolean} isLicensed - flag for the license status.
         * @property {Boolean} isLicensed - flag for the license status.
         */
        isLicensed: {
            type: Boolean,
            /* default: false,
            required: true */
        },
         isEnabled: {
            type: Boolean,
            default: false,
            // required: true
        },
        /**
         * @vue-prop {String} congtext - the context where the component is rendered ('org-list')
         * @property {String} congtext - the context where the component is rendered ('org-list', 'user-widget')
         */
        context: {
            type: String,
            default: 'org-list'
        }
    },
    components: {
    },
    watch: {
    }
})
/**
 * Component to display the current plan of a specified Organisation
 */
export default class BpsUserPlanLabel extends Vue {
}
