import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { User } from '@/types/User';
import Vue from 'vue';

@Module//({ namespaced: true, name: 'auth' })
export default class AuthModule extends VuexModule {
    status : string = '';
    token : string = localStorage.getItem('token') || '';
    refresh_token : string = localStorage.getItem('refresh_token') || '';
    //user : User = {usr_email: '', usr_id: -1, usr_name: '', usr_name_first: '', usr_org_name: ''};
    currentUser : User = JSON.parse( localStorage.getItem('user')!   ) || {usr_email: '', usr_id: -1, usr_name: '', usr_name_first: '', usr_org_name: ''};
    errorMessage : string = '';
    redirectPath : string = '';
    roles : String[] = new Array<String>();

    get isLoggedIn() : boolean {
        return !!this.token && !!this.refresh_token && this.currentUser.usr_id != -1;
    }

    get authStatus() : string {
        return this.status;
    }

    get sessionToken() : string {
        return this.token;
    }

    get user() : User {
      return this.currentUser;
    }

    get firstname() : string {
        return this.currentUser.usr_name_first;
    }

    get lastname() : string {
        return this.currentUser.usr_name;
    }

    get orgId() : number {
        return this.currentUser.usr_org_id!;
    }

    get orgName() : string {
        return this.currentUser.usr_org_name || '';
    }

    get userId() : number {
        return this.currentUser.usr_id;
    }

    get userImage() : string {
        return this.currentUser.usr_image || '';
    }

    get email() : string {
        return this.currentUser.usr_email;
    }

    get userRoles() : String[] {
      return this.roles;
    }

    get isSuperAdmin() : boolean {
        return this.roles.includes('app-admin');
    }

    get isLicensed() : boolean {
        // Uncomment the following return to re-enable license checking.
        return this.currentUser.usr_has_license || false;
        // return true;
    }

    get orgAdmin() : Number[] {
      return this.currentUser.usr_org_admin || [];
    }

    get orgPL() : Number[] {
      return this.currentUser.usr_org_pl || [];
    }

    get orgEmp() : Number[] {
      return this.currentUser.usr_org_emp || [];
    }

    get orgPers() : Number {
        if(this.currentUser.usr_org_pers != undefined && this.currentUser.usr_org_pers.length > 0) {
            return this.currentUser.usr_org_pers[0];
        }
        else return -1;
    }

    get proAdmin() : Number[] {
      return this.currentUser.usr_pro_admin || [];
    }

    get proRead() : Number[] {
      return this.currentUser.usr_pro_read || [];
    }

    get proWrite() : Number[] {
      return this.currentUser.usr_pro_write || [];
    }

    get isAppInError() : boolean {
        return this.status === 'error';
    }

    get isAppUnauthorized() : boolean {
        return this.status === 'unauthorized';
    }

    get loginRedirect() : string {
        return this.redirectPath;
    }

    @Mutation
    AUTH_REQUEST(){
        this.status = 'loading'
    }

    @Mutation
    SET_STATUS(status: string) {
      this.status = status;
    }

    @Mutation
    SET_ADMIN() {
        if(!this.roles.find((role:String) => role == 'app-admin')) {
            this.roles.push('app-admin');
        }
    }

    @Mutation
    SET_UNAUTHORIZED() {
      this.status = 'unauthorized';
    }

    @Mutation
    SET_USER(payload: User) {
        this.status = 'success';
        this.currentUser = payload;

        if(payload.usr_app_admin == true && !this.roles.find((role:String) => role == 'app-admin')) {
            this.roles.push('app-admin');
        }

        if(!this.roles.find((role:String) => role == 'usr_id')) {
            this.roles.push('usr_id');
        }
        if(payload.usr_org_admin != undefined  && payload.usr_org_admin.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_org_admin')) {
                this.roles.push('usr_org_admin');
            }
        }
        if(payload.usr_org_pl != undefined  && payload.usr_org_pl.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_org_pl')) {
                this.roles.push('usr_org_pl');
            }
        }
        if(payload.usr_org_emp != undefined  && payload.usr_org_emp.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_org_emp')) {
                this.roles.push('usr_org_emp');
            }
        }
        if(payload.usr_pro_admin != undefined  && payload.usr_pro_admin.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_pro_admin')) {
                this.roles.push('usr_pro_admin');
            }
        }
        if(payload.usr_pro_read != undefined  && payload.usr_pro_read.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_pro_read')) {
                this.roles.push('usr_pro_read');
            }
        }
        if(payload.usr_pro_write != undefined  && payload.usr_pro_write.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_pro_write')) {
                this.roles.push('usr_pro_write');
            }
        }
        localStorage.setItem('user', JSON.stringify(payload));
    }

    @Mutation
    SET_USER_PERMISSIONS(payload : User) {

        this.currentUser.usr_org_admin = payload.usr_org_admin;
        this.currentUser.usr_org_emp = payload.usr_org_emp;
        this.currentUser.usr_org_pl = payload.usr_org_pl;
        this.currentUser.usr_pro_admin = payload.usr_pro_admin;
        this.currentUser.usr_pro_read = payload.usr_pro_read;
        this.currentUser.usr_pro_write = payload.usr_pro_write;

        //reset roles array
        this.roles = new Array();

        if(payload.usr_app_admin == true && !this.roles.find((role:String) => role == 'app-admin')) {
            this.roles.push('app-admin');
        }
        if(!this.roles.find((role:String) => role == 'usr_id')) {
            this.roles.push('usr_id');
        }
        if(payload.usr_org_admin != undefined  && payload.usr_org_admin.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_org_admin')) {
                this.roles.push('usr_org_admin');
            }
        }
        if(payload.usr_org_pl != undefined  && payload.usr_org_pl.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_org_pl')) {
                this.roles.push('usr_org_pl');
            }
        }
        if(payload.usr_org_emp != undefined  && payload.usr_org_emp.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_org_emp')) {
                this.roles.push('usr_org_emp');
            }
        }
        if(payload.usr_pro_admin != undefined  && payload.usr_pro_admin.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_pro_admin')) {
                this.roles.push('usr_pro_admin');
            }
        }
        if(payload.usr_pro_read != undefined  && payload.usr_pro_read.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_pro_read')) {
                this.roles.push('usr_pro_read');
            }
        }
        if(payload.usr_pro_write != undefined  && payload.usr_pro_write.length>0) {
            if(!this.roles.find((role:String) => role == 'usr_pro_write')) {
                this.roles.push('usr_pro_write');
            }
        }
    }

    @Mutation
    ADD_PROJECT_ADMIN_PERMISSION(projectId:number) {
        if(this.currentUser != undefined && this.currentUser.usr_pro_admin != undefined) {
           this.currentUser.usr_pro_admin.push(projectId);
        }
    }

    @Mutation
    SET_USER_PICTURE(payload: string) {
      this.currentUser.usr_image = payload;
      localStorage.setItem('user', JSON.stringify(this.currentUser));
    }

    @Mutation
    SET_REDIRECT_PATH(payload: string) {
        this.redirectPath = payload;
    }

    @Mutation
    AUTH_SUCCESS(payload: { token:string, id_token:string, refresh_token:string, user: User, expires_in:number, refresh_expires_in:number }) {
      //console.log('AUTH_SUCCESS', payload);
      this.status = 'success';
      this.token = payload.token;
      this.refresh_token = payload.refresh_token;
      this.currentUser = payload.user;
      localStorage.setItem('token', this.token);
      localStorage.setItem('id_token', payload.id_token);
      localStorage.setItem('refresh_token', this.refresh_token);
      localStorage.setItem('user', JSON.stringify(payload.user));
      const expiration = Date.now() + (payload.expires_in.valueOf() * 1000);
      localStorage.setItem('expires_at', expiration.toString());
      const refresh_expiration = Date.now() + (payload.refresh_expires_in.valueOf() * 1000)
      localStorage.setItem('refresh_expires_at', refresh_expiration.toString());
      //just in case that flag is still there in LocalStorage although there is no valid reason for it to be.
      localStorage.removeItem('refresh_attempt');
    }

    @Mutation
    AUTH_ERROR() {
      this.status = 'error';
    }

    @Mutation
    AUTH_REMOVE() {
        this.token = '';
        this.currentUser = {usr_email: '', usr_id: -1, usr_name: '', usr_name_first: '', usr_org_name: ''};
        this.roles = new Array<String>();
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
        localStorage.removeItem('code');
        localStorage.removeItem('session_state');
        localStorage.removeItem('authorization_endpoint');
        //just in case that flag is still there in LocalStorage although there is no valid reason for it to be.
        localStorage.removeItem('refresh_attempt');
    }

    @Action
    login(payload: {token:string, refresh_token:string, user:User}) {
      this.context.commit('AUTH_SUCCESS', payload);
      // TODO i18n 'p_login_login_completed', {'first_name':payload.user.usr_name_first, 'last_name':payload.user.usr_name}
      Vue.$toast.success('Erfolgreich eingeloggt als '+ payload.user.usr_name_first + " " + payload.user.usr_name);
      //return {token, user};
    }

    @Action
    logout() {
      this.context.commit('AUTH_REMOVE');
      // TODO i18n 'p_login_logout_completed'
      Vue.$toast.success('Erfolgreich abgemeldet');
    }

    @Action
    sessionExpired() {
      this.context.commit('AUTH_REMOVE');
      // TODO i18n 'p_login_login_expired'
      Vue.$toast.info('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.');
    }

    @Action
    logoutWithError(payload : string) {
      this.context.commit('AUTH_REMOVE');
      if(!this.isAppUnauthorized) {
        this.context.commit('SET_REDIRECT_PATH', payload);
      }
      this.context.commit('SET_UNAUTHORIZED');
      return true;
    }

    @Action
    clearLogin() {
      this.context.commit('AUTH_REMOVE');
    }

    @Action
    setUser(payload: User) {
      this.context.commit('SET_USER', payload);
    }

    @Action
    setUserPicture(payload: string) {
      this.context.commit('SET_USER_PICTURE', payload);
    }

    @Action
    addProjectAdminPermission(projectId : number) {
        this.context.commit('ADD_PROJECT_ADMIN_PERMISSION', projectId);
    }

    @Action
    setUserPermissions(user : User) {
      this.context.commit('SET_USER_PERMISSIONS', user);
    }
}