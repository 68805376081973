import axios, { AxiosInstance, AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry'; // Retry interceptor function
import { APIError } from '@/types/APIError';
import Axios from 'axios';
import { ErrorHandler } from '@/utils/ErrorHandler';
import Vue from 'vue';
import router from '@/router';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();

    // automatic retries of requests that were interrupted because of an expired token
    axiosRetry(this.instance, { 
    // axiosRetry( axios, { 
        retries: 5,
        //retryDelay: axiosRetry.exponentialDelay,
        retryCondition(error) {
            console.log("AxiosRetry - check retryCondition", error.config);
            // Conditional check the error status code
            if(error.response != undefined) {
                switch (error.response.status) {
                case 503:
                    console.log("Retrying request with status 502", error.config);
                    return true; 
                    // Retry request with response status code 202, 
                    // ie. when a request was interrupted because of an expired token
                default:
                    return false; // Do not retry the others
                }
            }
            else {
                return false;
            }
          },
    });
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(async config => {
        //attach the current project id to every outgoin request
        /* const projectId = router.currentRoute.params.projectId;
        if(projectId != '' && projectId != undefined) {
            config.headers['X-ProjectId'] = projectId;
        } */
        const token = localStorage.getItem('token');
        if(token != '' && token != undefined) {
            // console.log(`Interceptor patches auth header with ..${token.slice(-40)} .`);
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        //TO BE TESTED remove content-type

        // Any user activiy is measured by REST calls (in main.ts).
        localStorage.setItem('last_user_activity', Date.now().toString());
        // console.log('User is active.');

        return config;
    });
  };

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };

  private _handleResponse = (response : AxiosResponse) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log("HttpClient Response Interceptor", response);
    return response;
  };

  protected _handleError = (error: any) => {
        //console.log("GOT AN ERROR", error.response.status, error.response.data.internal_code)
        //console.log("GOT AN ERROR", error, typeof error)
        if(error.response.status == 202) {
            //console.log("HTTP error handler", error.response.status, error.response.statusText, pendingRequests);
            return Promise.reject(error.response.data);
        }
        else if(error.response.status === 503) {
            router.push({
                name: 'maintenance'
            })
        }
        //scenario for when the API replies with a not found message
        else if(error.response.status === 404) {
            router.push({
                name: 'not-found'
            });
            Vue.$toast.error(error.response.data.message);
        }
        else if((error.response.status === 401 || error.response.status === 400) &&
        (
            error.response.data.internal_code == 'AUTH' 
            || error.response.data.internal_code == 'AUTH_NO_TOK' 
            || error.response.data.internal_code == 'AUTH_EXP_TOK' 
            || error.response.data.internal_code == 'AUTH_BE_TOK' 
            || error.response.data.internal_code == 'AUTH_INV_TOK'
            || error.response.data.internal_code == 'TRUSTID_TOKEN_ERROR'
            || error.response.data.internal_code == 'TRUSTID_INV_AZP'
        )) {
            //return the rejected promise
            //return Promise.reject(error.response.data);
            const refreshAttempted = localStorage.getItem('refresh_attempt');
            const refreshToken = localStorage.getItem('refresh_token');
            const originalConfig = error.config;
            //console.log("Issue with token expired", originalConfig);
            const expiredAt = localStorage.getItem('refresh_expires_at'); // Set by auth.modules.ts .
            if(!refreshAttempted && refreshToken != null) {
            //if(originalConfig._retry != true && refreshToken != null) {
                originalConfig._retry = true;
                localStorage.setItem('refresh_attempt', Date.now().toString());
                console.log("Now trying to refresh it (in _handleError)", refreshAttempted, originalConfig._retry);

                const refresh_endpoint = process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_AUTH_SUFFIX + 'refresh';
                //console.log("About to refresh the token", refreshToken);
                this.instance.post(refresh_endpoint, {
                    refresh_token: refreshToken,
                })
                .then(response => {
                    //console.log("Transmitted TrustID code from LocalStorage", response.data);
                    localStorage.setItem('token', response.data.token.access_token);
                    if(response.data.token.refresh_token != null) {
                        localStorage.setItem('refresh_token', response.data.token.refresh_token);
                    }
                    //console.log("user received after refresh", response.data.usr, Axios.request(originalConfig))
                    localStorage.setItem('user', JSON.stringify(response.data.usr));
                    const expiration = Date.now() + (response.data.token.expires_in.valueOf() * 1000)
                    localStorage.setItem('expires_at', expiration.toString());
                    localStorage.removeItem('refresh_attempt');
                    //inject the newly refreshed token
                    originalConfig.headers['Authorization'] = `Bearer ${response.data.token.access_token}`;
                    //return Promise.resolve(true);
                    //retries the original request with updated auth token
                    return Axios.request(originalConfig);
                })
                .catch(error => {
                    const err : APIError = error as APIError;
                    if(err.internal_code == 'TRUSTID_TOKEN_ERROR' || error.response.data.internal_code == 'TRUSTID_INV_AZP') {
                        //Refresh token is probably expired too, we should completely logout the user 
                        //and redirect them to the login page
                        //console.log("Token Error");
                        this.forceLogout();
                    }
                });
            }
            else if(refreshToken == null) {
                this.forceLogout();
            }
            else if(expiredAt && refreshAttempted && refreshAttempted > expiredAt + 60000) {
                this.forceLogout();
            }
            else {
                //refresh is being attempted by another instance of HTTPClient
                //I should wait and retry later
                //this.forceLogout();
                //console.log("Refresh in progress, will resubmit request later");
            }
        }
        //scenario for when the API replies with a not authorized message for a WRITE request (ie. different from GET)
        else if(error.response.status === 401 && 
            error.response.config.method != 'get' &&
            (error.response.data.internal_code == 'AUTH_PRO'
            || error.response.data.internal_code == 'TRUSTID_INV_AZP')
        ) {
            Vue.$toast.error(error.response.data.message);
        }
        //scenario for when the API replies with a not authorized message for a READ request (ie. GET requests)
        else if(error.response.status === 401 && 
            error.response.config.method == 'get' &&
            (error.response.data.internal_code == 'AUTH_PRO'
            || error.response.data.internal_code == 'TRUSTID_INV_AZP')
        ) {
            router.push({
                name: 'not-authorized'
            });
        }
        //scenario for when the API replies with a message related to TRUSTID issues for e-mail address not confirmed yet
        else if(error.response.status === 401 && 
            error.response.data.internal_code == 'TRUSTID_EMAIL_NOT_VERIFIED'
        ) {
            // TODO i18n 'p_login_fail_email_not_verified'
            Vue.$toast.error("Login fehlgeschlagen - Ihre E-Mail-Adresse wurde nicht überprüft. Bitte wenden Sie sich an den CRB-Support");
        }
        //scenario for when the API replies with a message related to TRUSTID issues for lack of client role
        else if(error.response.status === 401 && 
            error.response.data.internal_code == 'TRUSTID_NO_CRB_KUNDE_ROLE'
        ) {
            // TODO i18n 'p_login_fail_no_user'
            Vue.$toast.error("Es ist leider ein Fehler aufgetreten. Bitte melden Sie sich ab und wieder an. Falls dies nicht zielführend ist, kontaktieren Sie bitte CRB unter support@crb.ch.");
        }
        else {
            const errorHandler : ErrorHandler= new ErrorHandler();
            error.response.data.message = errorHandler.process(error.response.data);
            Vue.$toast.error(error.response.data.message);
            return Promise.reject(error.response.data);
        }
    };
    
    private forceLogout() {
        const auth_endpoint = localStorage.getItem('authorization_endpoint');

        if(auth_endpoint != null) {

            // we override auth_endpoint to reflect the current location for the redirect_uri parameter
            //const actual_location = window.location.origin + window.location.pathname;
            //const corrected_redirect_uri = auth_endpoint.replace(/redirect_uri.*/, "redirect_uri="+actual_location);
            //console.log("Forcing clean logout and redirecting to ", corrected_redirect_uri);

            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('id_token');
            //localStorage.removeItem('refresh_token');
            localStorage.removeItem('code');
            localStorage.removeItem('session_state');
            localStorage.removeItem('refresh_attempt');
            //localStorage.removeItem('password_reset_location');
            //localStorage.removeItem('account_edit_location');

            //window.location.href = corrected_redirect_uri;
            router.push({
                name: 'login',
                params: { message: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.' }
            })
        }
    }
}